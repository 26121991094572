export const ar_AR = {
    'ordre': 'الرتبة',
    'perime': 'منتهي الصلوحية',
    'cheque': 'شيكات',
    'Lait': 'حليب',
    'Pots': 'علبة',
    'Gajou': 'صندوق',
    'Ratio': 'Ratio',
    'Offre Commercial': 'عرض خاص',
    'Encours Commercial par Client': 'الجاري حسب الموزع والحريف',
    'Attention journée sans plan de tournée': 'إنتباه المستعمل ليس لديه خط سير',
    'Programé': 'مبرمج',
    'Clients programmés  non visité': 'زبائن مبرمجة و لم تقع زيارتهم',
    'Camion_Prevendeur ajoutée avec Succès': 'تم إضافة الشاحنة بنجاح',
    'Camion_Prevendeur supprimée avec Succès': 'تم حذف الشاحنة بنجاح',
    'Total cheque': 'مجموع الشيكات',
    'depense': 'المصاريف',
    'le': "حرر في",
    'Nature': "النوع",
    'Retour Hier': 'ترجيع أمس',
    'NOMADIS': 'نوماديس',
    'Nomadis': 'نوماديس',
    'commercial': 'الموزع',
    'Chauffeur': 'السائق',

    'Camion_Prevendeur modifiée avec Succès': 'تعديل شاحنة التاجر بنجاح',
    'Livreur': 'رجل تسليم',
    'Superviseur': 'مشرف',
    'Commercial': 'الموزع',
    'commercials': 'الموزعين',
    'Distributeurs': 'الموزعين',
    'Cashvan': 'مسوق',
    'Cashvan et Livreur':' مسوق و رجل تسليم '  ,
    'Pré vendeur': 'مندوب',
    'Code Pré vendeur': 'رمز المندوب',
    'vente': 'بيع',
    'Vente': 'بيع',
    'ventes': 'المبيعات',
    'commande': 'الطلب',
    'Commande': 'الطلب',
    'commandes': 'الطلبيات',
    'Commandes': 'الطلبيات',
    'bl': 'فاتورة',
    'Bl': 'فاتورة',
    'bls': 'فواتير',
    'Bls': 'فواتير',
    'Factures': 'فواتير',
    'clients_visite': 'زيارة العملاء',
    'recrutement_client': 'عدد العملاء',
    'Recrutement PDV': 'عدد نقاط البيع',
    'Nombre PDV': 'عدد نقاط البيع',
    'recette': 'تحصيل',
    'credit': 'ائتمان',
    'Credit': 'ائتمان',
    'Crédit': 'دائن',
    'Recouvrment / Vente ce mois': 'تحصيل/ مبيعات الشهر الجاري',
    'Encours / Moyenne des ventes mensuelles': 'المديونية / معدل المبيعات الشهري',
    'Dernière facture': 'آخر فاتورة',
    'Dernière recouvrement': 'آخر تحصيل',
    'Nbre de Jours': 'عدد الأيام',
    'Age dette': 'عمر المديونية ',
    'Vente Total': 'المبيعات ',
    'Mois': 'شهر',
    'Moyenne': 'المعدل',
    'Crédit Total': 'الاتمان الجملي',
    'Nbre de Recouvrement': 'عدد التحصيلات',
    'Avoir': 'الرجوع',
    'Historique Vente': 'تاريخ المبيعات',
    
    'avoir': 'رجوع',
    'ESP/': 'نقدا',
    'espece': 'نقود',
    'Espece': 'نقود',
    'Cheque': 'شيكات',
    'cheque': 'شيك',
    'cheques': 'شيكات',
    'Cheques': 'شيكات',
    'recouvrements': 'تحصيل الديون',
    'recouvrement': 'تحصيل الديون',
    'Recouvrement': 'تحصيل الديون',
    'tx_vente': 'معدل البيع',
    'Taux de vente': 'معدل البيع',
    'tx_visite': 'معدل الزيارة',
    'Nv Client': 'زبون جديد',
    'R.tourne': 'احترام خط السير',
    'visites': 'الزيارات',
    'visite': 'الزيارة',
    'Visite': 'الزيارة',
    'nombre': 'عدد',
    'Historique': 'تاريخ خط السير',
    'tournees': 'خطوط السير',
    'Nb Tournées': 'عدد خطوط السير',
    'Tournees': 'خطوط السير',
    'tournee': 'خط السير',
    'Tournee': 'خط السير',
    'Copier tournee': 'نسخ خط السير ',
    'RC': 'السجل التجاري',
    'societe': 'الشركة',
    'Sous société': 'الشركة الفرعية',
    'gouvernorat': 'المحافظة',
    'Region': 'الإقليم',
    'region': 'الإقليم',
    'regions': 'المناطق',
    'Regions': 'المناطق',
    'gamme': 'المجموعة',
    'Nbr Bl cloturé': 'عدد الفاتورات المغلقة ',
    'Nbr Bl non cloturé': 'عدد الفاتورات غير  المغلقة ',
    'famille': 'عائلة',
    'sous famille': 'عائلة فرعية',
    'SousFamille': 'عائلة فرعية',
    'marque': 'علامة',
    'Gamme': 'المجموعة',
    'Famille': 'عائلة',
    'Sous Famille': 'عائلة فرعية',
    'Taux de Remise': 'سعر الخصم',
    'Marque': 'علامة',
    'Nb PDV': 'عدد نقاط البيع',
    'Succés': 'نجاح',
    'Succès': 'نجاح',
    'Croissance': 'النمو',
    'Tx': 'نسبة',
    'TOP': 'أفضل',
    'Montant': 'المبلغ',
    'Ajouter un groupe': 'أضف مجموعة',
    'Affectaion Groupe Produit': 'تعيين مجموعات المنتجات',
    'Ajouter une Affectation': 'إضافة تعيين',
    'Ajouter une marque': 'أضف ماركة',
    'Ajouter une famille': 'أضف عائلة',
    'Ajouter une sous famille': 'إضافة عائلة فرعية',
    'Ajouter une gamme': 'إضافة مجموعة',
    'Ventes par gamme': 'المبيعات حسب المجموعة',
    'Ventes par gamme Aujourd\'hui': 'المبيعات حسب المجموعة لليوم',
    'Ventes par gamme mois': 'المبيعات حسب المجموعة لشهر',
    'Ventes par gamme année': 'المبيعات حسب المجموعة لسنة',
    'Commandes par gamme Aujourd\'hui': 'الطلبيات حسب المجموعة لليوم',
    'Commandes par gamme mois': 'الطلبيات حسب المجموعة لشهر',
    'Commandes par gamme année': 'الطلبيات حسب المجموعة لسنة',
    'Crédit/Rec': 'الديون',
    'Déconnexion': 'الخروج',
    'Taux de Couverture client par vendeur (%)': 'معدل تغطية العملاء حسب البائع (٪)',
    'nbr de visite * 100 / objectif.total )  / v.nbr_jour_travaille': 'عدد الزيارات * 100 / الأهداف) / عدد أيام العمل)',
    'Chiffre d\'affaire Net (DT)': 'رقم المعاملات الصافي بالدينار',
    'Chiffre d\'affaire Net Commande (DT)': "رقم المعاملات الصافي للطلبيات بالدينار",
    'Total Net': ' المجموع الصافي',
    'Chiffre d\'affaire': 'رقم المعاملات',
    'Moyenne CA': 'معدل رقم المعاملات',
    'nbre de client avec vente / nbre de  client visité': 'عدد العملاء الذين لديهم مبيعات / عدد العملاء الذين تمت زيارتهم',
    'Tx.D': 'نسبة التوزيع',
    'DN.G': 'التوزيع الرقمي العالمي',
    'Rapport Commande Par Region/Mois': 'تقرير الطلبيات حسب المناطق والاشهر',
    'Oui': 'نعم',
    'Non': 'لا',
    'Arreté le présent Bon de livraison a la somme de': 'تحتوي الفاتورة الحالية على مبلغ',
    'Arreté le présent Recouvrement a la somme de': 'تحتوي مذكرة تسليم استرداد الديون الحالية على مبلغ',
    'Arreté le présent Avoir a la somme de': 'يحتوي المرتجع الحالي على مبلغ',
    'Arreté la présente commande a la somme de': 'يحتوي الطلب الحالي على مبلغ',
    'Arreté la présente Facture a la somme de': 'تحتوي الفاتورة الحالية على مبلغ',
    'Timbre': 'ختم',
    'Banque': 'البنك',
    'Date_echeance': 'تاريخ نهاية الصلاحية',
    'Ref': 'الرمز',
    'Cheque impayé': 'شيك غير مدفوع',
    'Sens ecriture': 'معنى الكتابة',
    'Debiteur': 'خصم من الحساب',
    'Débit': 'خصم  ',
    'Crediteur': 'زيادة للحساب',
    'Numero Cheque': 'رقم الشيك',
    'BL_N°': 'رقم الفاتورة',
    'Num Chéque': 'رقم الشيك',
    'Paiement': 'خلاص',
    'Paiement Cheque': 'الدفع بالشيك',
    'Mouvement du Client': 'حركة العميل',
    'Date Echeance Cheque': 'تاريخ نهاية الشيك',
    'Date Echeance': 'تاريخ نهاية',
    'Banque Cheque': 'بنك الشيك',
    'Num Cheque': 'رقم الشيك',
    'Nouveau utilisateur': 'مستخدم جديد',
    'Modifier utilisateur': 'تغييرالمستخدم',
    'Changer mot de passe': 'تغيير كلمة المرور',
    'Date Echeance Traite': 'تاريخ نهاية الكمبيالة',
    'Traite': 'كمبيالة',
    'Banque Traite': 'بنك الكمبيالة',
    'Num Traite': 'رقم الكمبيالة',
    'login': 'دخول',
    'Mot de passe actuelle': 'كلمة المرور الحالية',
    'Nouveau mot de passe': 'كلمة مرور جديدة',
    'Confirmer Nouveau mot de passe': 'تأكيد كلمة المرور الجديدة',
    'Ajouter un localite': 'أضف محلة',
    'Vente par Marque': 'بيع حسب العلامة التجارية',
    'Vente par fournisseur': 'بيع حسب المورد',
    'Vente par Famille': 'البيع حسب المجموعات',
    'Commande par Marque': 'الطلبيات حسب العلامة التجارية',
    'Commande par Famille': 'الطلبيات حسب المجموعات',
    'Correction Recouvrement': 'تصحيح تحصيل',
    //dates
    'aujourdhui': 'اليوم',
    'Aujourd\'hui': 'اليوم',
    'mois': 'الشهر',
    'date': 'تاريخ',
    'Date': 'التاريخ',
    'Date Prevu de livraison': 'التاريخ المتوقع للتسليم',
    'jour': 'اليوم',
    'Jour': 'اليوم',
    'Date Début': 'تاريخ البداية',
    'Date Fin': 'تاريخ الانتهاء',
    'Type Visite': 'نوع الزيارة',
    'annee': 'السنة',
    'Année': 'السنة',
    'commandes regions': 'الطلبيات حسب المناطق',

    'Date debut Credit': ' تاريخ بداية الائتمانات',
    'Date fin Credit': ' تاريخ نهاية الائتمانات',
    'Date debut Visite': ' تاريخ بداية الزيارات',
    'Date fin Visite': ' تاريخ نهاية الزيارات',
    // kilometrage et heure
    'TimeWork': 'وقت عند الحريف',
    'TimeOut': 'وقت  خارج الحريف',
    'Kilometrage': 'عدد الكيلومترات',
    'KD': 'عدد الكيلومترات البداية',
    'KF': 'عدد الكيلومترات النهاية',
    'Distance': 'المسافة',
    'Temps': 'المدة',
    'Heure debut': 'ساعة البداية',
    'Heure fin': 'ساعة النهاية',
    'HD': ' ساعة البداية',
    'HF': 'ساعة النهاية',
    'Box': 'الصناديق',
    'Taux Tva': 'معدل الضريبة',

    'Rec': 'الاستخلاص',

    "Type": 'نوع العملية',
    'Janvier': 'يناير',
    'Fevrier': 'فبراير',
    'Février': 'فبراير',
    'Mars': 'مارس',
    'Avril': 'أبريل',
    'Mai': 'مايو',
    'Juin': 'يونيو',
    'Juillet': 'يوليو',
    'Aout': 'أغسطس',
    'Septembre': 'أيلول',
    'Octobre': 'أكتوبر',
    'Novembre': 'تشرين الثاني',
    'Decembre': 'ديسمبر',
    'KPI': 'مؤشر أداء',
    'distribution': 'التوزيع',
    'nombre de jour travaillé': 'عدد ايام العمل',
    'Jour repos': 'يوم الراحة ',
    'J.T': 'عدد ايام العمل',
    'Date prévu prochaine Visite': 'تاريخ الزيارة التالي',

    // navbar 
    'Vous avez': 'عندكم',
    'Credit pour demain': 'الائتمان ليوم غد',
    'Cheques pour demain': 'الشيكات ليوم غد',
    'nouveaux clients': 'زبائن جدد ',
    'Francais': 'اللغة الفرنسية',
    'Arabe': 'اللغة العربية',
    // menu
    'dashboard': 'لوحة القيادة',
    'analyses': 'تحليل',
    'routing': 'طرقات',
    'routings': 'طرقات',
    'Routings': 'طرقات',
    'routes': 'طرقات',
    'Routes': 'طرقات',
    'utilisateurs': 'المستخدمين',
    'parametrages': ' الإعدادات',
    'clients': 'الزبائن',
    'Clients': 'الزبائن',
    'client': 'زبون',
    'Client': 'الزبون',
    'tarification': 'التسعير',
    'objectif': 'الهدف',
    'Valider les Objectifs': 'تسجيل الأهداف',
    'Cadeaux': "تذاكير الهدايا",
    'Connecté': 'متصل',
    'nv Objectifs': "اضافة اهداف بالجملة",
    'Objectif': 'الهدف',
    'objectifs': 'الأهداف',
    'fidelites': 'نقاط الوفاء',
    'zoning': 'التقسيم',
    'Zoning': 'التقسيم',
    'produits': 'المنتجات',
    'produit': 'المنتوج',
    'chargements': 'تحميل',
    'equipements': 'المعدات',
    'Equipements': 'المعدات',
    'equipements clients': 'معدات الزبائن',

    'prixachat': 'سعر الشراء',
    'prixachat1': '1سعر الشراء',
    'prixachat2': 'سعر الشراء2',
    'prixachat3': 'سعر الشراء3',

    'Prix achat': 'سعر الشراء',
    'Prix achat 1': '1سعر الشراء',
    'Prix achat 2': 'سعر الشراء2',
    'Prix achat 3': 'سعر الشراء3',

    'P.Details': 'تفاصيل السعر',
    'P.Depositaire': 'سعر المستودع',

    'Nb Clients': 'عدد الزبائن',
    'mouvements': 'الحركات',
    'Mouvements': 'الحركات',
    'dn': 'التوزيع الرقمي',
    'carburant': 'الوقود',
    'stock': 'مخزون',
    'rapport': 'تقرير',
    'rapport visites': 'تقرير الزيارات',
    'Rapport Visite par Mois': 'تقرير الزيارات حسب الشهر',
    'caisse': 'الخزينة',
    'achat': 'الشراء',
    'Achat': 'الشراء',
    'Stock Depot': 'المخزون',
    'Type utilisateur': 'نوع المستعمل ',
    'Createur': 'الكاتب',
    'dlc': ' الصلاحية',
    'dlcC': ' الصلاحية',
    'DLC': ' الصلاحية',
    'Prix achat HT': 'سعر الشراء باستثناء الضريبة',
    'Prix achat TTC': 'سعر الشراء جميع الضرائب مشمولة',
    'todo list': 'قائمة ما يجب عمله',
    'devise': 'العملة',
    'integration': 'اندماج',
    'erp': 'تخطيط موارد المؤسسات',
    'ERP': 'تخطيط موارد المؤسسات',
    'notes': 'ملاحظات',
    'Notes': 'ملاحظات',
    'R.I.B': 'رقم البنك',
    'Demande chargement': 'طلب تحميل',
    'Total Commande Livré': 'مبيعات الطلبيات ',


    // analyses
    'Journalier': 'يوميا',
    'journalier': 'يومي',
    'periodique': 'دوري',
    'periode': 'فترة',
    'Vendeur': 'البائع',
    'Rapport Journalier': 'تقرير اليومي',
    'Rapport Journalier Produit': 'تقرير المنتج اليومي',
    'Rapport Journalier SousFamille': 'التقرير اليومي للأسر الفرعية للمنتوجات',
    'Rapport Commercial/Mois': 'تقرير البائع / الشهر',
    'Rapport Tournée': 'تقرير خط السير ',
    'Rapport Periodique': 'التقرير الدوري',
    'Vente Par Region': 'بيع حسب المنطقة',
    'Commande Par Region': 'الطلبيات حسب المنطقة',
    'Vente Periode': 'التقرير الدوري',
    'Commande Periode': 'طلب التقرير الدوري',
    'Vente Famille/Commercial': 'تقرير بيع عائلة المنتج / البائع ',
    'Vente Famille/Gamme': 'تقرير بيع عائلة المنتجات / مجموع المنتجات  ',
    'Vente Client_Date': 'مبيعات العملاء حسب التاريخ',
    'DN_Vendeur': 'التوزيع الرقمي حسب كل عميل',
    'Vente sousFamille_Client': 'مبيعات اسر المنتوجات من قبل العملاء',
    'Commande sousFamille_Client': 'طلبيات اسر المنتوجات من قبل العملاء',
    'Vente et DN par Produit': 'بيع و التوزيع الرقمي حسب كل منتوج ',
    'Vente et DN par Produit region': 'بيع والتوزيع الرقمي حسب كل منتوج وكل منطقة',
    'Analyse Tournee': 'تقرير تحليل خط السير ',
    'Clients Inactifs': 'زبائن غير فعالة',
    'Vente/Commercial': 'تقرير بيع حسب كل بائع ',
    'Suivi Commercial': 'متابعة كل بائع ',
    'Suivi Tournee': 'متابعة  خط السير ',
    'Gouvernorat': 'محافظة',
    'Gouvernorats': 'محافظات',
    'gouvernorats': 'محافظات',
    'Ajouter un gouvernorat': 'إضافة محافظة',
    'Zone': 'منطقة',
    'Ajouter une zone': 'أضف منطقة',
    'Zones': 'المناطق',
    'Stat Chargement': 'إحصائيات التحميل',
    'Top 10': 'أفضل 10',
    'Vente Client': 'مبيعات العملاء',
    'Credit par Commercial': 'الائتمان من قبل كل بائع ',
    'List Credit par Commercial': 'قائمة الائتمانات لكل بائع ',
    'Reglement par Commercial': 'التنظيم المصرفي لكل بائع ',
    'Reglement': 'التنظيم',
    'Visites prévus par Commercial': 'الزيارات المخطط لها من قبل تجاري',
    'KPI Commercial par Mois': 'مؤشر أداء كل تجاري حسب الشهر',
    'KPI Commercial': 'مؤشر أداء كل تجاري',
    'Type': 'نوع',
    'Global societe': 'معلومات عامة',
    'Début': 'بداية',
    'Stat-vente P': 'إحصاءات المبيعات1',
    'Stat-vente C': 'إحصاءات المبيعات2',
    'Fin': 'نهاية',
    'Veuillez enregistrer le fichier sous classeur Excel (*.Xlsx)': 'يرجى حفظ الملف تحت مصنف Excel (* .xlsx)',
    'Nbr Commandes': 'عدد الطلبيات',
    'NB/BL': 'عدد الفواتير',
    'NB/Jour': 'عدد الأيام',
    'NB/Cmd': 'عدد الطلبيات ',
    'NB/Commande': 'عدد الطلبيات ',
    'NB/Avoir': 'عدد فواتير الرجوع',
    'NB/Visites': 'عدد الزيارات',
    'NB/Clients': 'عدد الزبائن',
    'NB/Recouvrement': 'عدد تحصيل الديون',
    'Tx succés': 'نسبة النجاح',
    'Tx succès': 'نسبة النجاح',
    'Tx Commande': 'نسبة الطلب',
    'Tx respect': 'نسبة الاحترام',
    'Client visité': 'الزبائن الذي تم زيارتهم',
    'Clients visitée': 'الزبائن الذي تم زيارتهم',
    'nbr Client/Vente': 'رقم العملاء / المبيعات',
    'Nombre des Clients avec Vente': ' عدد الزبائن الذي تم البيع لهم ',
    'Nombre des Clients': ' عدد الزبائن',
    'Ajout Client': 'إضافة زبون ',
    'Ajouter Client': 'إضافة زبون ',
    'Modifier détails client': 'تغيير تفاصيل الزبون',
    'KPI Global': 'مؤشر الأداء الكلي ',
    'Nb Client': 'عدد الزبائن',
    'Nb Clients': 'عدد الزبائن',
    'R.tourne': 'احترام خط السير ',
    'Kilometrage': 'عدد الكيلومترات',
    'Vente par produit': 'بيع حسب المنتوج',
    'Produit': 'المنتوج',
    'Nom Client passager': 'اسم الزبون',
    'Quantite': 'الكمية',
    'qte': 'الكمية',
    'Montant': 'المبلغ',
    'Commandes TTC': 'الطلبيات جميع الضرائب مشمولة',
    'Ventes TTC': 'المبيعات جميع الضرائب مشمولة',
    'Credit TTC': 'الائتمان  جميع الضرائب مشمولة',
    'Prix TTC': 'سعر جميع الضرائب مشمولة ',
    'Prix_TTC': 'سعر',
    'Prix HT': 'السعر باستثناء الضريبة',
    'Montant HT': 'المجموع باستثناء الضريبة',
    'Montant TTC': '  المجموع جميع الضرائب مشمولة',
    'Choisir': 'إختيار',
    'Tous': 'الكل',
    'KPI Vendeur': 'مؤشر أداء المسوقين',
    'KPI Prevendeur': 'مؤشر أداء المندوبين',
    'Rapport Vente Par Region': 'تقرير المبيعات حسب المنطقة',
    'Rapport DN Par Region': 'تقرير التوزيع الرقمي حسب كل منطقة ',
    'export': 'تحميل',
    'csv': 'CSV',
    'xlsx': 'xlsx',
    'Vente par Commercial': ' المبيعات حسب كل بائع',
    'Anaylse vente Famille/Gamme': 'تقرير تحليل مبيعات أسر المنتجات حسب مجموع المنتجات  ',
    'Chiffre': 'رقم',
    'chiffre': 'رقم',
    'Pourcentage': 'نسبة مئوية',
    'Secteur': 'قطاع',
    'Adresse': 'العنوان',
    'Code': 'الرمز',
    'Total': 'إجمالي ',
    'ANALYSE TOURNEE': 'تحليل خط السير ',
    'LUNDI': 'الاثنين',
    'MARDI': ' الثلاثاء',
    'MERCREDI': ' الاربعاء',
    'JEUDI': 'الخميس',
    'VENDREDI': ' الجمعة',
    'SAMEDI': 'السبت',
    'DIMANCHE': ' الأحد',

    'Lundi': 'الاثنين',
    'Mardi': ' الثلاثاء',
    'Mercredi': ' الاربعاء',
    'Jeudi': 'الخميس',
    'Vendredi': ' الجمعة',
    'Samedi': 'السبت',
    'Dimanche': ' الأحد',

    'lundi': 'الاثنين',
    'mardi': ' الثلاثاء',
    'mercredi': ' الاربعاء',
    'jeudi': 'الخميس',
    'vendredi': ' الجمعة',
    'samedi': 'السبت',
    'dimanche': ' الأحد',

    'lun': 'الاثنين',
    'mar': ' الثلاثاء',
    'mer': ' الاربعاء',
    'jeu': 'الخميس',
    'ven': ' الجمعة',
    'sam': 'السبت',
    'dim': ' الأحد',

    'Nom': 'الاسم',
    'Magasin': 'إسم المحل',
    'Routing': 'الجولة',
    'Date création': 'تاريخ الإنشاء',
    'Operation': 'عملية',
    'KPI Commande par Gouvernorat': 'مؤشر أداء الطلبيات حسب كل محافظة',
    'KPI Commande par Zone': 'مؤشر أداء الطلبيات حسب كل منطقة ',
    'Ecart': 'الفرق ',
    'nbr produit unique': 'عدد المنتجات الفريدة',
    'nbr produit vendu': 'عدد المنتجات المباعة',
    'quantite vendu': 'الكمية المباعة',
    'N°': 'رقم',
    'Numero': 'رقم',
    'Tarif spécial': 'تخفيض التعريفة',
    'Mobile': 'الهاتف المحمول',
    'mobile': "متحرك",
    "fixe": "ثابت",
    'Fix': 'رقم الهاتف ',
    'Tel': 'الهاتف',
    'Dernier visite': 'اخر زيارة',
    'Date Dernier Visite': 'تاريخ اخر زيارة',
    'Observation': 'ملاحظة',
    'Encours': 'الجاري',
    'Date Credit': 'تاريخ الائتمان ',
    'Traite': 'الكمبيالة',
    'Retenue Avoir': 'خصم من المورد',
    'Retenue': 'الخصومات',
    'Cause': 'السبب',
    'Commentaire': 'تعليق',
    'prévu prochaine Visite': 'الزيارات المبرمجة',
    'Nombre de PDV Planifié': 'عدد المواعيد المبرمجة ',
    'Nombre de Clients visités': 'عدد الزبائن الذي تم زيارتهم',
    'Nombre de Clients avec Ventes': 'عدد الزبائن بالمبيعات ',
    'Contribution / Van %': 'مساهمة كل شاحنة ',
    'Objectif CA TTC': 'هدف ايرادات جميع الضرائب مشمولة ',
    'ttc / totalttc': 'الضرائب مشمولة / جميع الضرائب مشمولة',
    'Objectif Vs Réalisation %': 'الهدف مقابل الانجاز',
    'Realisation': 'الانجاز',
    'Chiffre d\'affaire Net': 'صافي الايرادات ',
    'Taux de succés': 'نسبة النجاح ',
    'Taux de Visites': 'نسبة الزيارات',
    'Tx visites': 'نسبة الزيارات',
    'Tx visite': 'نسبة الزيارات',
    'Recrutment PDV': 'عدد نقاط البيع',
    'CH TTC / nbre de client avec vente': 'جميع الضرائب مشمولة / عدد الزباءن الذين اشتروا',
    'Dropsize (DT)': 'جميع الضرائب مشمولة / عدد الزباءن الذين اشتروا',
    'Clients Visités': 'زيارة الزبائن',
    'Nbr de licence achetée': 'عدد التراخيص المشتراة',
    'Nbr de licence installée': 'عدد التراخيص المثبتة',

    //
    'Frequence': 'التردد',
    'frequence': 'التردد',
    'Chaque Semaine': 'كل اسبوع',
    'Semaine Paire': 'اسبوع زوجي',
    'Semaine Impaire': 'اسبوع فردي',
    '1er Semaine': 'الأسبوع الأول',
    '2eme Semaine': 'الأسبوع الثاني',
    '3eme Semaine': 'الأسبوع الثالث',
    '4eme Semaine': 'الأسبوع الرابع',

    'semaine': 'كل اسبوع',
    'semainepaire': 'اسبوع زوجي',
    'semaineimpaire': 'اسبوع فردي',
    '1er_semaine': 'الأسبوع الأول',
    '2eme_semaine': 'الأسبوع الثاني',
    '3eme_semaine': 'الأسبوع الثالث',
    '4eme_semaine': 'الأسبوع الرابع',

    'Cumul Credit': 'تراكم الائتمان',
    // DN
    'Distribution numérique': 'التوزيع الرقمي',
    'Intervale': 'الفواصل',
    // clients
    'Categorie': 'فئة',
    'Catégorie': 'فئة',
    'Categorie Commercial': 'فئة الموزع',
    'Rechercher': 'البحث',
    'Nombre de Client par Activité': 'عدد الزبائن حسب النشاط',
    'Nombre de Client par Catégorie': 'عدد الزبائن حسب الفئة',
    'Activité': 'النشاط',
    'Route': 'الطريق',
    'En cours': 'الجاري',
    'Date création': 'تاريخ الإنشاء',
    'Action': 'إعدادات',
    'suivant': 'التالي',
    'precedant': 'السابق',
    'Echeance': 'تاريخ الاستحقاق',
    'Modifier routes': 'تغيير طريق ',
    'Modifier Route': 'تغيير طريق ',
    'Modifier': 'تغيير',
    'Supprimer': 'حذف',
    'Prime': 'منحة',
    'Réalisations': 'الإنجازات',
    'Ajouter Tournee': 'إضافة خط سير',
    'Nouvelle Route': 'إضافة طريق جديد',
    'hors tournee': 'خارج خط السير',
    'CIN': 'رقم بطاقة الهوية',
    'Regime fiscale': 'النظام الضريبي',
    'MF': 'المعرف الجبائي',
    'Adresse de facturation': 'عنوان الفوترة',
    'Autorisation Credit': 'إذن الائتمان',
    'Catégorie commerciale': 'الفئة التجارية',
    'Plafond credit': 'سقف الائتمان',
    'Autorisation cheque': 'إذن الشيكات',
    'Plafond credit cheque': 'سقف الائتمانات والشيكات',
    'Plafond credit traite': 'إذن الكمبيالات والشيكات',
    'Autorisation traite': 'إذن الكمبيالات',
    'Passager': 'مار',
    'Accés Metrage': 'وصول لقطات',
    'Tarif': 'تعريفة',
    'tarif': 'تعريفة',
    'tarif1': '1تعريفة',
    'tarif2': '2تعريفة',
    'Changement Prix': 'تغيير السعر',
    'Code SMS': 'رمز الرسائل القصيرة',
    'Livraison': 'توصيل',
    'Couleur': 'اللون',

    // routings
    'Matricule': 'رقم الشاحنة',
    'Matricule Interne': 'الرقم الداخلي في الشركة',
    'Camion': 'الشاحنة',
    'Route Carte': 'خريطة الطريق',
    'Suivi': 'المراقبة',
    'Debut/Fin': 'بداية /نهاية',
    'Depot': 'المستودع',
    'Dépots': 'المستودع',
    'Moyenne': 'المعدل',
    'Distributeur': 'الموزع',
    'Nouveau Distributeur': 'موزع جديد',
    'valider': 'تأكيد ',
    'Valider': 'تأكيد ',
    'frequance': 'تواتر',
    'facture': 'فاتورة ',
    'Facture': 'فاتورة ',
    'RemiseEspece': 'تخفيض نقدي',
    'Remise Espece': 'تخفيض نقدي',
    'Total TTC': 'إجمالي والضرائب مشمولة',
    'Total HT': 'إجمالي باستثناء الضريبة   ',
    'Net a payer': 'صافي للدفع',
    'Net': 'صافي',
    'Total': 'الإجمالي',
    'Non visité': 'لا زيارة',
    'routes modifié avec succès': 'تغيرت الطرق بنجاح',
    'Veuillez bien choisir une nouvelle route et au mois un client': 'يرجى اختيار طريق جديد وعميل واحد على الأقل',

    // ventes 
    'Bon de livraison': 'فاتورة',
    'Entete supprimées': 'الملفات المحذوفة',
    'Total remise': 'مجموع التخفيض',
    'Total net': 'مجموع الملفات',
    'Total recouvrement': 'مجموع التحصيل',

    'Bon de Recouvrement': 'وصل استرداد الديون',
    //tarification
    'Ajouter un Tarif': 'إضافة تسعيرة',
    'Tarif': 'التسعيرة',
    'P. TTC': 'السعر المدفوع جميع الضرائب مشمولة',
    'P. HT': 'السعر باستثناء الضريبة',
    // OBJECTIFS
    'Vue globale': 'نظرة شاملة',
    'Ajouter nouveau objectif': 'إضافة هدف جديد',
    'Ajouter des objectifs (Chiffre d\'affaire / visites)': '(إضافة أهداف ( رقم المعاملات / الزيارات',

    'Libelle': 'الاسم',
    'Libelle Objectif': 'اسم الهدف',
    'Assortiment': 'تشكيلة',

    'P.Vente': 'سعر البيع',
    'P.Assortiment': 'سعر التشكيلة',

    'Ajouter un Objectif': 'أضف هدفًا',
    'prix': 'سعر',
    'Prix': 'سعر',
    'Ajouter': 'إضافة',
    //fidelite
    'Ajouter Fidelite': 'إضافة برنامج وفاء ',
    'Fidelite': 'برنامج وفاء',
    'Equivalence': 'التكافؤ',
    'Points': 'نقاط وفاء ',
    'Actif': 'نشيط',
    'Inactif': 'غير نشيط',
    'Conditions': 'شروط',
    'Ajouter une Condition gratuite': 'إضافة شرط المجانية ',
    'Affectation des gammes à l\'utilisateur': 'تعيين النطاقات للمستخدم',
    'Position de': 'موقع',
    //fournisseurs
    'Nom et Prénom': 'الإسم واللقب',
    'Nom': 'الإسم',
    'Prénom': 'اللقب',
    'Grade': 'الرتبة',
    'Role': 'دور',
    'Metier': 'الحرفة',
    'Téléphone': 'الهاتف',
    'Actif': 'نشيط',
    'Actualisé': 'تحديث',
    'Ajouter un Fournisseur': 'إضافة مورد',
    'Fournisseurs': 'الموردين',
    'Fournisseur': 'المورد',
    // pays
    'Pays': 'البلد',
    'Longitude': 'خط الطول ',
    'Latitude': 'خط العرض ',
    //societe
    'Societe': 'الشركة',
    'Fiche Societe': '',
    'logo': 'الشعار',
    'Matricule Fiscale': 'المعرف الجبائي',
    'Matricule fiscale': 'المعرف الجبائي',
    'telephone': 'الهاتف',
    'Telephone': 'الهاتف',
    'Fax': 'فاكس',
    'email': 'البريد الإلكتروني',
    'Email': 'البريد الإلكتروني',
    'mot de passe': 'كلمة السر',
    'mot de passe admin': 'كلمة سر المسؤول',
    'admin': 'مسؤول',
    'Admin': 'مسؤول',
    'Responsable': 'مسؤول العملية',
    'prefix_bl': 'فاتورة تبدأ ب:',
    'prefix_devis': 'التسعير تبدأ ب:',
    'prefix_commande': 'الطلبية تبدأ ب:',
    'prefix_recouvrement': 'تحصيل الديون يبدأ ب:',
    'Pied_page': 'أخر الصفحة',
    'dernier page': 'أخر صفحة',
    'Voir Images': 'انظر الصور',
    'Images': 'الصور',
    'Non lu': 'وضع علامة كغير مقروءة',
    'Marquer comme non lu': 'وضع علامة كغير مقروءة',
    'Entete': 'رأس الصفحة',
    'page 1': 'رأس الصفحة',
    'Changement_prix': 'تغيير السعر',
    'SMS Confirmation': 'تأكيد الرسائل القصيرة',
    'Message': 'الرسالة',
    'Nouveau Societe': 'شركة جديدة',
    'Image': 'صورة',
    'Enregistrer': 'تسجيل',
    // Camion_Prevendeur
    'Camion_Prevendeur': 'شاحنة',
    'O.Achat Avoir': 'التزام الشراء في حالة وجود مرتجع',
    'Synchronisation': 'التزامن',
    'Taux Avoir': 'نسبة المرتجع ',
    'Gestion Client': 'إدارة العملاء',
    'Modification Client': 'تغيير العميل',
    'Colisage': 'التعبئة',
    'colisage': 'التعبئة',
    'Sync Client': 'مزامنة العميل',
    'Total CMD': 'إجمالي الطلبيات',
    'Total Avoir': 'إجمالي الرجوع',
    'Total Recouvrement': 'إجمالي استرجاع الديون',
    'Total BL': 'إجمالي الفاتورات',
    'Sequence Client': 'تسلسل العملاء ',
    'Modification remise': 'تعديل الخصم',
    'Plafond Credit': 'سقف الائتمان',
    'Vente client avec credit': 'بيع للعملاء بالاجل',
    'Paiement Esp': 'الدفع نقدا',
    'Obligation Achat en cas d\'avoir': 'التزام الشراء في حالة وجود مرتجع',
    'et': 'و',
    'Modifier Camion': 'تعديل شاحنة',
    // Equipements
    'Ajouter un Equipement': 'إضافة معدات',
    'Printer': 'طابعة',
    'Etat': 'الحالة',
    'Adresse MAC': 'عنوان بلوتوث',
    'Derniere sync': 'آخر مزامنة',
    "Dérnier Syncronisation": 'آخر مزامنة',
    // Affectations

    'Affectation': 'تعيين',
    'Affectations': 'تعيين',
    'Affectation Prix': 'تعيين الأسعار',
    'Ajouter Affectation': 'إضافة وصال',
    'Ajouter une delegation': 'إضافة منطقة',
    'Model xlsx': 'نموذج xlsx',
    'Model': 'نموذج',
    'nbr pdvs': 'عدد نقاط البيع',
    // Delegations
    'delegation': 'منطقة',
    'Delegation': 'منطقة',
    'Delegations': 'المناطق',
    'Gouvernorat': 'محافظة',
    'Localites': 'محليات',
    'code postale': 'الرمز البريدي',
    'Ajouter une region': 'أضف منطقة',
    'Secteurs': 'مقاطعات',
    'Ajouter un Secteur': 'أضف مقاطعات',
    'Chiffre par délegation': 'رقم حسب الوفد',
    // produits
    'Gratuites': 'المجانية',
    'Pack': 'حزمة',
    'Promotion': 'التخفيضات',
    'Promotions': 'التخفيضات',
    'Télécharger le model': 'تنزيل النموذج',
    'TXT prix': 'TXT سعر',
    'Import mise a jour prix du produit format csv/txt (colone1=vide;colone2=produit_code,colone3=vide;colone4=prix_ttc)': 'تعديل سعر المنتوج ',
    'TXT': 'TXT',
    'Import produits Wavesoftsoft erp': 'استيراد بيانات المنتوج Wavesoftsoft erp',
    'Import Nomadis Produits Format : (txt,csv,xls,xlsx)': 'استيراد بيانات المنتوج شكل txt,csv,xls,xlsx',
    'Quantite Vendu': 'الكمية المباعة',
    'Quantite Stock': 'كمية المواد',
    'Activite': 'نشاط',
    'Quantite min': 'كمية الحد الأدنى',
    'Dimensions': 'الأبعاد',
    'hauteur': 'الارتفاع',
    'largeur': 'العرض',
    'profondeur': 'العمق',
    'Code garantie': 'رمز الضمان',
    'Nouveau produit': 'منتوج جديد',
    'Unite': 'وحدة',
    'TVA': 'ضريبة القيمة المضافة',
    'tva': 'ضريبة القيمة المضافة',
    'Prix de vente Public': 'سعر البيع العام',

    // gratuite
    'Gratuités': 'المجانية',
    'Cumulable': 'زيادة',
    'Ajouter une gratuité': 'أضف مجانية',
    'Ajouter gratuité': 'أضف مجانية',
    'Groupe': 'المجموعة',
    'Affectation Clients': 'تعيين الزبائن',
    'Affectation Clients Gratuites': 'تعيين المجانية للزبائن',
    'Affectation Clients Promotion': 'تعيين التخفيضات للزبائن',
    'Affectation Produits Promotion': 'تعيين التخفيضات للمنتجات',
    'A.Clients': 'عدد التخفيضات للزبائن',
    'A.Produits': 'عدد التخفيضات للمنتجات',

    'Ajouter une Affectation Client': 'إضافة  تخفيض للزبون',
    'Ajouter une Affectation Produit': 'إضافة تخفيض للمنتوج',
    'Ajouter une Affectation Gratuite': 'إضافة مجانية للمنتوج',

    'Statut': 'وضع',
    'Negociable': 'قابل للتفاوض',

    //pack
    'Details': 'تفاصيل',
    'Détails': 'تفاصيل',
    'Ajouter Composition': 'إضافة مكون',
    'Position': 'مكان',
    'Localite': 'مكان',
    'Numero Config': ' رقم التكوين',
    'Configuration': 'التكوين',
    'Ajouter une remise': 'إضافة تخفيضات',
    'Total Remise': 'مجموع التخفيضات',
    'Remise': 'تخفيض',
    'Paiement Espece': 'الدفع نقدا',
    'Min': 'الحد الأدنى ',
    'Max': 'الحد الأقصى',
    'Max BL': 'الحد الأقصى للفاتورة',
    'Max facture': 'الحد الأقصى للفاتورة',
    'Max CMD': 'الحد الأقصى للطلب',
    'Max Devis': 'الحد الأقصى للعملات',
    'Max avoir': 'الحد الأقصى للرجوع',
    'Max Recouvrement': 'الحد الأقصى للاسترداد الكلي',
    'Code Response': 'رمز الاستجابة',
    'Voir Liste': 'انظر القائمة',
    'Ajouter un Camion ou Prevendeur': 'أضف شاحنة أو بائع مسبق',
    'Details Pack': 'تفاصيل الحزمة ',
    // MOUVEMENT
    'Numero operation': 'رقم العملية',
    'Fichier Excel': 'ملف excel ',
    //chargement 
    // 'Importation chargement Camion Format CSV/TXT avec separateur ;': 'استيراد تحميل CSV/TXT',
    'Importation chargement Camion Format : xlsx/xls colonne 1: code colonne 2: qte et noublier pas de mettre les titres': 'ولا تنسى أن تضع العناوين qte : الخانة 2 code : الخانة 1 xlsx/xls : استيراد تحميل شاحنة على شكل',

    'Importation Mouvement chargement Camion Format CSV/TXT avec separateur ; FoxPro': 'CSV / TXT  FOXPRO ; استيراد حركة تحميل شاحنة تنسيق مع فاصل',
    'Importation Mouvement chargement Camion Format CSV/TXT avec separateur ; Wavesoft': 'CSV / TXT  Wavesoft ; استيراد حركة تحميل شاحنة تنسيق مع فاصل',
    'Liste des demandes chargements': 'قائمة طلبات التحميل',
    'Liste des chargements': 'قائمة التحميل',
    'Liste des Factures': 'قائمة الفواتير',
    'Demande de chargements': 'طلب التحميل ',
    'Demande de': 'طلب',
    'Code a barre': 'الرمز الشريطي',
    'Nouveau chargement': 'تحميل جديد ',
    'Modification du chargement': 'تغير التحميل',
    'Code chargement': 'رمز التحميل',
    //equipement client
    'Numero de serie': 'الرقم التسلسلي',
    'Refrigerateur': 'ثلاجة',
    'Presentoire': 'عرض',
    //notes
    'Liste des Notes': 'قائمة الملاحظات',
    'Envoyé': 'إرسال المذكرة',
    'Recu': 'تلقي المذكرة',
    'Objet': 'الموضوع',
    'Fidelite du Client': 'نقاط وفاء العميل',
    'Total des points': 'نقاط الوفاء',
    'Fidelite': 'نقاط الوفاء',
    //carburant
    'Carburant': 'فاتورة الوقود ',
    'Ticket': ' التذاكر ',
    'Code Commercial': 'رمز الموزع',
    'Code client': 'رمز الزبون',
    'Cout de Visite par Commercial': 'تكلفة الزيارة من قبل الموزع',
    'Cout de Visite': 'تكلفة الزيارة',
    'Consommation': 'استهلاك',

    // stocks
    'Code en douane': 'رمز الجمارك ',
    'Bon de Sortie N°': 'شهادة خروج رقم',
    'Destination': 'الوجهة ',
    'Produit en rupture': 'المنتج في حالة نفاذ',
    'Valeur Stock': 'قيمة المخزون ',
    'Valeur': 'القيمة',
    //todo
    'Todo': 'ما يجب عمله',
    'Collaborateur': 'متعاون',
    'Cible': 'المستهدف ',
    'Status': 'الصفة ',
    'Titre': 'العنوان',
    'Description': 'وصف',
    'Date Before': 'التاريخ قبل',
    'Avant': 'قبل',
    'Apres': 'بعد',
    'Duree': 'مدة',
    'PG': 'PG',
    'Priorite': 'أولوية',
    'Cloture': 'إغلاق',
    'Date after': 'التاريخ بعد',
    'Image Obligatoire': 'الصورة اجبارية',
    'Preuve': 'إثبات',
    'Ajouter une tache': 'إضافة مهمة للقيام بها',
    'par defaut': 'افتراضيا',

    //Rapport Visite
    'Rapport Visite': 'تقرير الزيارة',
    'Ajouter un Element': 'إضافة عنصر',
    'Rapport Details': 'التفاصيل',
    'Stop': 'التوقف',
    'Element': 'العنصر',
    'Cloture': 'إغلاق',
    'Bloquer': 'تجميد',
    //ACHAT
    'User': 'المستعمل',
    'Ajouter Commande': 'أضف طلب',
    'Ajouter achat': 'إضافة شراء',
    //Devises
    'Devises': 'العملات',
    'Ajouter devise': 'إضافة عملة',
    'Taux': 'معدل',
    //caisses
    'Recette': 'الإيرادات',
    'Recouvrement caisses': 'الإيرادات المودعة ',
    'Caisse Commercial': 'الصندوق التجاري',
    'Caisses': 'الصندوق ',
    'Recette Aujourd\'hui': 'تحصيل اليوم',
    'Journée': 'اليوم',
    //params promo
    'Message Promo': 'رسالة التخفيضات ',
    'Parametage Mobile': 'تعديل الجوال',
    'Ligne': 'سطر',
    'Impression Recette': 'طباعة التحصيل ',
    'Modification Mobile Client': 'تعديل جوال الزبون',
    'Export': 'تصدير',
    'Import': 'استيراد',
    'Télécharger la liste de clients': 'قم بتنزيل قائمة الزباءن',
    'Fermer': 'اغلق',
    'Ajouter une promo': 'إضافة تخفيض',
    //message
    'Votre compte a été désactivée.': '.تم تعطيل العقد الخاص بك ، اتصل بالمسؤول',
    'Votre compte a été expirée.': '.انتهى عقدك',
    'Veuillez verifier les champs!': '.الرجاء التثبت من المعلومات ',
    'Erreur de verification de compte, veuillez contacter l\'admin.': '.خطأ في التحقق من العقد ، يرجى الاتصال بالمشرف',

    // 404
    'Attention!': '!تحذير',
    'Oups!': '!تحذير',

    "vous n'avez pas la permission d'accéder à cette page": 'ليس لديك إذن بالوصول إلى هذه الصفحة',

    //message swal
    'Commande ajoutée succés': 'تمت إضافة الطلب بنجاح',
    'Commande ajoutée avec succés': 'تمت إضافة الطلب بنجاح',
    'Attention': 'تحذير',
    'erreur d\'insertion!': 'خطأ في الإدراج!',
    'Voulez vous vraiment supprimer cette ligne!': 'هل تريد حقًا حذف هذا السطر!',
    'Ligne Supprimée avec succés': 'تم حذف الخط بنجاح',
    'Code Commande existe deja': 'رمز الطلب موجود بالفعل',
    'produit existe deja!': 'المنتج موجود بالفعل!',
    'Commande n\'existe pas': 'الطلب غير موجود',
    'ligne n\'existe pas!': 'السطر غير موجود!',
    'Supprimée': 'تم تنفيذ الحذف',
    'activite modifiée avec succés': 'تم تعديل النشاط بنجاح',
    'Voulez vous vraiment supprimer cette activite!': 'هل تريد حقًا حذف هذا النشاط!',
    'activite Supprimée avec Succès': 'تم حذف النشاط بنجاح',
    'Vous ne pouvez pas supprimer cette activite': 'لا يمكنك حذف هذا النشاط',
    'activite ajoutée avec Succès': 'تمت إضافة النشاط بنجاح',
    'Veuillez bien remplir les deux champs date': 'يرجى ملء كلا التاريخين',
    'Veuillez bien remplir le champ date debut': 'يرجى ملء تاريخ البدء',
    'Veuillez bien remplir le champ date fin': 'يرجى ملء تاريخ الانتهاء',
    'Suppression': 'حذف البيانات',
    'code bl': 'رقم الفاتورة',
    'Code BL': 'رقم الفاتورة',
    'Oui, supprimer!': 'نعم ، احذف',
    'Non, Annuler!': 'لا ، ألغي',
    'Succé': 'النجاح',
    'Document supprimée avec succées': 'تم الحذف  بنجاح',
    'Error': 'خطأ',
    'Veuillez choisir un distributeur': 'الرجاء اختيار موزع',
    'Veuillez choisir un depot': 'الرجاء اختيار مستودع',
    'Veuillez choisir un client': 'الرجاء اختيار عميل',
    'Veuillez bien remplir la liste des produits': "الرجاء ملء قائمة المنتجات",
    ' virgule': 'فاصلة',
    ' Dinar': 'دينار ',
    ' Cent': 'مئة ',
    ' Cents': 'مئاة ',
    'cent': 'مئة',
    "cent ": ' مئة',
    " cents": 'مئاة ',
    " cent ": ' مئة ',
    's et': ' و ',
    'mille ': 'ألف ',
    ' mille ': ' ألف ',
    ' million ': ' مليون ',
    " milliards ": 'مليار',
    "cents": 'مائة',
    "cent": 'مائة',
    'milles': 'ألاف ',
    'mille': 'ألف ',
    'millimes': 'مليم',
    'millime': 'مليم',
    /*
    "un": 'واحد',
    "deux": 'اثنان',
    "trois": 'ثلاثة',
    "quatre": 'أربعة',
    "cinq": 'خمسة',
    "six": 'ستة',
    "sept": 'سبعة',
    "huit": 'ثمانية',
    "neuf": 'تسعة',
    "dix": 'عشرة',
    "onze": 'أحد عشر',
    "douze": 'اثنا عشر',
    "treize": 'ثلاثة عشر',
    "quatorze": 'أربعة عشر',
    "quinze": 'خمسة عشر',
    "seize": 'ستة عشر',
    "dix-sept": 'سبعة عشر',
    "dix-huit": 'ثمانية عشر',
    "dix-neuf": 'تسعة عشر',
    "vingt": 'عشرون',
    "trente": 'ثلاثون',
    "quarante": 'أربعون',
    "cinquante": 'خمسون',
    "soixante": 'الستون',
    "quatre-vingt": 'ثمانون',
    "septante": 'سبعون',
    "nonante": 'التسعينات',
    "huitante": 'الثمانينات',
    
*/
    ' et ': ' و ',
    "Veuillez bien selectionner le client!": 'الرجاء اختيار العميل!',
    "Veuillez choisir au moins une bl!": 'الرجاء اختيار مذكرة تسليم واحدة على الأقل!',
    "Produit(s) gratuit(s)": 'منتج (منتجات) مجانية',

    // authorization:
    'Authorisation': 'ترخيص',
    'Authorisation client': 'ترخيص الزبون',
    'Roles': 'الأدوار',
    'Ajouter un rôle': 'أضف دورًا',


    // typevisite:
    'Stock': 'مخزون',
    'Recouvrement': 'تحصيل الديون',
    'Exclusive Concurent': 'منافس',
    'Concurent': 'منافس',
    'Patron Absent': 'غير موجود',
    'Ferme': 'مغلق',

    // consigne
    'Consignes': 'الصناديق',
    'Consigne': 'الصناديق',
    'Utilisateur': 'المستخدم',
    'Entre': 'الدخول',
    'Sortie': 'الخروج',
    'Solde': 'الرصيد',

    // typemouvements:
    'venteC': 'بيع للعميل',
    'chargement': 'تحميل',
    'Chargement': 'تحميل',
    'retour': 'ترجيع المستودع',
    'Reprise': 'ترجيع المستودع',
    'casse': 'كسر',
    'casseC': 'كسر',
    'DLC CASSE': 'كسر',
    'Casse': 'كسر',
    'Reservation': 'الحجز',
    'Sécurite': 'الأمن',
    'retourC': 'الرجوع من العميل',
    'reenvoyer': 'إعادة تحميل',
    'inventaire': 'جرد',
    "Inventaire": "جرد",

    'Produits dispnibles pour le chargement': 'المنتوجات المتاحة للتحميل',
    'Tous les produits': 'كل المنتوجات',
    'Liste Factures': 'قائمة الفواتير',
    'Liste Commandes': 'قائمة الطلبات',
    'Liste Credit pour demain': 'قائمة استرجاع الديون ليوم غد',
    'Date Échéance': 'تاريخ الموعد النهائي',
    'Liste des chèques pour demain': 'قائمة الشيكات ليوم غد',
    'Code demande': ' رمز طلب التحميل',

    'quantité chargements': 'الكمية المحملة',
    'quantité retour': 'كمية الرجوع',
    'quantité vente réel': 'كمية المبيعات الفعلية',
    'quantité vente Net': 'كمية المبيعات الصافية',
    'quantité vente facturé': 'كمية المبيعات المفوترة',
    'quantité non facturé': 'كمية غير مفوترة',
    'Prix Unitaire': 'سعر الوحدة',
    'Total réel': 'المجموع الحقيقي',
    'Total facturé': 'المجموع المفوتر',
    'vente réel': 'المبيعات الحقيقية',
    'Vente Net': 'صافي المبيعات ',
    'vente facturé': 'المبيعات المفوترة',
    'non facturé': 'الغير المفوتر',
    'Prix Carton': 'سعر الكرتون',
    'Carton': 'كرتون',
    'Cartons': 'كرتون',

    // alert
    'Voulez vous vraiment supprimer ce Client!': '!هل تريد حقًا حذف هذا العميل',
    'Client Supprimée avec Succès': 'تم سحب العميل بنجاح',
    'Voulez vous vraiment supprimer cette route!': '!هل تريد حقًا حذف هذا الطريق',
    'route modifiée avec Succès': 'تم تعديل الطريق بنجاح',
    'a ete ajouté avec succé': 'تمت إضافته بنجاح',
    'Voulez vous vraiment supprimer ce Tournée!': '!هل تريد حقًا حذف هذه الجولة',
    'Tournée Supprimée avec Succès': 'تم سحب الجولة بنجاح',
    'Route Supprimée avec Succès': 'تم سحب الطريق بنجاح',
    'Tournée modifiée avec Succès': 'تم تعديل الجولة بنجاح',
    'Frequence modifiée avec Succès': 'تعديل التردد بنجاح',
    'Voulez-vous vraiment Transformer cette commande en bon de livraison': 'هل تريد حقًا تحويل هذا الطلب إلى فاتورة',

    'Montant vente total': 'إجمالي مبلغ البيع',
    'Montant vente à crédit': 'مجموع البيع آجل',
    'A terme': 'آجل',
    'Montant vente chèque': 'مجموع البيع بالشيكات',
    'Montant recouvrement espece': 'الديون المستخلصة نقدا',
    'Montant recouvrement': 'الديون المستخلصة',
    'Montant recouvrement chéque': 'الديون المستخلصة بالشيكات',
    'Montant à payer': 'المبلغ الواجب دفعه',
    'Montant à payer (espece + cheque)': 'المبلغ الواجب دفعه : النقد والشيكات',
    'Montant à payer par chéque': 'مبلغ الدفع عن طريق الشيكات',
    'Cumul écart caisse': 'الفرق التراكمي للخزينة',
    'Cumul écart caisse du jour': 'الفرق التراكمي لخزينة اليوم',
    'Ecart caisse du jour': 'الفارق النقدي اليوم',
    'Nombre de Facture': 'عدد الفواتير',
    'Tx de Retour': 'معدل العائدات',
    'Liste des Recouvrements': 'قائمة استرجاع الديون',
    'Caisse ajoutée avec Succès': 'تم اضافة السحب بنجاح',
    'Veuillez bien remplir le champ commercial': 'الرجاء إدخال الموزع والبحث',


    'Envoi vers android': 'إرسال إلى الهاتف',
    'piece': 'قطعة',
    'Correction': 'تصحيح',
    'correction': 'تصحيح',
    'BT Imprimante': 'بلوتوث الطابعة',
    'LIV': 'بائع',
    'MAG': 'أمين المستودع',
    'Magasinier': 'أمين المستودع',
    'Bilan Journalier': 'الحساب اليومي',
    'PaiementAvoir': 'الدفع عن طريق الرجوع',
    'cheque impaye': 'شيك غير مدفوع',
    'Numero de la pièce': 'رقم الوصل',
    'Chargement Type': 'تحميل مثالي',
    'Convertir en Box': 'تحويل إلى صندوق',
    'Convertir en piéce': 'تحويل إلى قطعة',
    'Transformer en bon de livraison': 'تحويل إلى فاتورة',
    'Cette Commande correspond a la bon de livraison N°:': ': يتوافق هذا الطلب مع الفاتورة رقم',
    'Ventes par gammes et familles': 'المبيعات حسب المجموعة والعائلة',
    'Rapport de Tournée le': 'تقرير عمل يوم',
    'de Monsieur': 'للسيد',
    'Suivi Commercial par mois': 'متابعة المبيعات حسب الموزع/الاشهر',
    'Obligation achat avoir': 'الالتزام بالشراء للقيام بعملية رجوع',
    'Obligation photo visite': 'ضرورة الصورة فالزيارة',
    'Sync Clients': 'تزامن العملاء',
    'Max Client': 'الحد الأقصى للعملاء',
    'Authorization Client': 'إظافة عميل',
    'Authorization Modification Client': 'تغير معطيات العميل',
    'plafond Credit': 'سقف الائتمان',
    'Modification remise': 'تعديل الخصومات',
    'Blocage Commande': 'حجب الطلبيات',
    'version': 'النسخة',
    'Confirmation annulation Entete': ' إلغاء الفاتورة',

    'Affectation users camion': 'تعيين مستخدم الشاحنة',
    'parametrages': 'الإعدادات',
    'Parametrages': 'الإعدادات',
    'Type d\'opération': 'نوع العملية',
    'NB/CMD': 'عدد الطلبيات',
    'NB/Visite': 'عدد الزيارات',
    'Total Visite': ' عدد الزيارات الجملي',
    'encours': 'الجاري',
    'Encours Saisie': 'المبلغ المستحق',
    'Plafond': 'السقف',
    'Sens': 'جهة',
    'Credit enCours': 'المتخلد بالذمة',
    'List Cheques': 'قائمة الشيكات',
    'Suivi Commercial par client': 'المتابعة التجارية من قبل الحرفاء',
    'Suivi Clients': 'متابعة الحرفاء',
    'Benefice': 'الفائدة',
    'Objectif nombre de visite par jour': 'هدف عدد الزيارات كل يوم',
    'Objectif chiffre d affaire par jour': 'هدف  رقم المعاملات كل يوم',

    //Dashboard
    'Global societe': 'المؤسسة العامة',
    'admin': 'المشرف',
    //bilan journalier
    'Stat-vente': 'إحصائيات البيع',
    'Rapport stats/Produits': 'تقرير إحصائيات المنتجات',
    'categorie': 'صنف',
    'Rapport stats/Commercial': 'تقرير إحصائيات الباعة',
    '% RC': 'ترجيع ثابت ',
    '% R.DLC': 'نهاية الصلوحية %',
    '% R.Casse': 'كسر %',
    'Vente Reel': 'بيع حقيقي',
    'Vente Facturé CL': 'المبيعات المفوترة',
    'Vente reel Client Passager': 'المبيعات الحقيقية للحرفاء العابرين',
    'CA': 'حجم المعاملات',
    'Id': 'المعرف',
    'Montant a payer': 'المبلغ الواجب دفعه',
    'Chéques': 'الشيكات',
    'Chéque': 'الشيكات',
    'Depense': 'المصاريف',
    'gratuite': 'المجانيات',
    'voulez vous vraiment supprimer cette caisse!': 'هل تريد حقًا حذف هذا الصندوق؟',
    'Caisse': 'الصندوق',
    'Oui': 'نعم',
    'Non': 'لا',
    'Caisse supprimée avec Succès': 'تم حذف الصندوق بنجاح',
    'Total': 'إجمالي',
    'Ecart': 'الفارق',
    'Versement Banque': 'الدفع المصرفي',
    'Ancien Crédit': 'المتخلد بالذمة',
    'gratuite': 'المجانيات',
    'Caisse Modifié avec Succès': 'تم تعديل الصندوق بنجاح',
    'Valider Caisse': 'تثبيت الصندوق',
    'Cloture': 'إغلاق',
    'Succé': 'نجاح',
    'Caisse validé avec succé': 'تم تثبيت الصندوق بنجاح',
    'Ok': 'نعم',
    'Solde Caisse': 'إجمالي الصندوق',
    'Reference': 'مرجع',
    'Valeur DLC/CASSE': 'قيمة الصلاحية و الكسر',
    'Valeur DLC/CASSE (Android)': '(من الهاتف)قيمة الصلاحية و الكسر',
    'Total espece': 'إجمالي النقد',
    'Montant vente avoir': 'قيمة الخلاص بالترجيع',
    'Montant vente espece': 'قيمة الخلاص بالنقد',
    'Montant vente Ticket': 'قيمة الخلاص بالتذاكر',
    'Vente Aujourd\'hui': ' قيمة مبيعات اليوم',
    'Caisse Aujourd\'hui': 'قيمة الايرادات اليوم',
    'veuillez saisir le montant total du recouvrement  espece, chéque, traite et ticket': 'الرجاء إدخال اجمالي التحصيل ( نقد،شيكات،كمبيالة وتذاكر)',
    'somme des encaissments (les opérations de ventes et de recouvrements) saisi par l utilisateur sur le telephone (espece + chèque + ticket)': '  إجمالي الايرادات (بيع وتحصيل) التي قام المستخدم بإدراجها في هاتفه (نقد + شيكات + تذاكر )',
    'somme(chargement - reprise * prix unitaire) on tient pas en compte le retour DLC et casse': 'إجمالي (التحميل  - الرجوع) ضارب السعر دون احتساب الصلاحيات والكسر  ',
    'total chèque vente et recouvrement': 'إجمالي مبلغ الشيكات بيع وتحصيل  ',
    'total espece vente et recouvrement': 'إجمالي النقود بيع وتحصيل  ',
    'valeur de la dlc et casse saisi par le magasinier': 'قيمة الصلاحيات والكسر المتأتية من أمين المستودع  ',
    'Paiement par Avoir': 'الخلاص بطريقة الرجوع',
    'Montant du Recouvrement': 'إجمالي التحصيل',


    //'Débit':
    'Note': 'ملاحظات',
    //Dépot:
    'Séléctionnez un élément dans la liste': 'حدد عنصرًا من القائمة',
    'Correction ajoutée avec succées': 'تم التصحيح بنجاح',
    'Succés': 'نجاح',
    'Transformer Retour Conforme en Chargement': 'تحويل الترجيع الصحيح إلى التعبئة',
    'Code Caisse': 'رمز الصندوق',
    'Terminal': 'الهاتف',
    'Vente Facturé': 'المبيعات المفوترة',


    //Dashboard

    'Global societe': 'المؤسسة العامة ',
    'admin': 'المشرف',

    //bilan journalier

    'Stat-vente': 'إحصائيات البيع',
    'Rapport stats/Produits': 'تقرير إحصائيات المنتجات ',
    'categorie': 'صنف',
    'Rapport stats/Commercial': 'تقرير إحصائيات الباعة',
    '% RC': 'ترجيع ثابت ',
    '% R.DLC': 'نهاية الصلوحية %',
    "% R.Casse": "كسر %",
    "Vente Reel": "بيع حقيقي",
    "Vente Facturé CL": "المبيعات المفوترة ",
    "Vente reel Client Passager": "المبيعات الحقيقية للحرفاء العابرين ",
    "CA": "حجم المعاملات",
    "Id": "المعرف",
    "Montant a payer": "المبلغ الواجب دفعه",
    "Chéques": "الشيكات",
    "Depense": "المصاريف",
    "gratuite": "المجانيات",
    "voulez vous vraiment supprimer cette caisse!": "?هل تريد حقًا حذف هذا الصندوق",
    "Caisse": "الصندوق",
    "Oui": "نعم",
    "Non": "لا",
    "Caisse supprimée avec Succès": "تم حذف الصندوق بنجاح ",
    "Total": "إجمالي",
    "Ecart": "الفارق",
    "Versement Banque*": "الدفع المصرفي",
    "Ancien Crédit*": "المتخلد بالذمة ",
    "gratuite*": "المجانيات",
    "Caisse Modifié avec Succès": "تم تعديل الصندوق بنجاح ",
    "Valider Caisse": "تثبيت الصندوق ",
    "Cloture": "إغلاق ",
    "Succé": "نجاح",
    "Caisse validé avec succé": "تم تثبيت الصندوق بنجاح",
    "Ok": "نعم",
    "Solde Caisse": "إجمالي الصندوق ",
    "Reference": "مرجع",
    "Débit": "مديون",
    "Note": "ملاحظات",
    "Dépot": "المستودع",
    "Séléctionnez un élément dans la liste": "حدد عنصرًا من القائمة",
    "Correction ajoutée avec succées": "تم التصحيح بنجاح",
    "Succés": "نجاح",
    "Transformer Retour Conforme en Chargement": "تحويل الترجيع الصحيح إلى التعبئة",
    "Code Caisse": "رمز الصندوق ",
    "Terminal": "الهاتف",
    "Vente Facturé": "المبيعات المفوترة ",
    "aucune caisse a modifier": "لا يوجد صندوق للتعديل ",

    //Chargement:
    "Demande": "قائمة طلبات التحميل",
    "Liste": "قائمة التحميل",
    "quota": "حصة نسبية",
    "Vider Quota": "التفريغ",
    "Voulez vous vraiment supprimer tout les donnees de quota!": "هل أنت متأكد من حذف جميع البيانات ",
    "quota supprimé avec Succès.": "تم التعديل بنجاح ",
    "Stock camion en piéce": "مستودع الشاحنة  بالقطعة",
    "Stock Dépot en piéce": "مستودع المخزون بالقطعة",
    "Stock Dépot en casier": "مستودع المخزون بالكرتونة",
    "Date Modification": "تاريخ التعديل",
    "Commande en Piece": " الطلبيات بالقطعة  ",
    "Credit collectivite": "الائتمان المجتمعي",
    "Credit collectivite depot": "الائتمان المجتمعي في المخزن",

    //ventes
    "Ajouter Bon de livraison": "إضافة فاتورة ",
    "Paiement Traite": "الخلاص بالكمبيالة",
    "Ticket Cadeaux": "تذاكر الهدايا ",
    "Paiement en instance": "الدفع عند العودة",

    //gestion commandes 
    "Prepartion des Commandes": "إعداد الطلبيات ",
    "Suivi Commande et livraison en cours": "متابعة الطلبيات والتسليم",
    "Preparation": "التحضير",
    "Chargé": "تعبئة",
    "Sortie": "خروج",
    "Livré": "تسليم",
    "Annulé": "إلغاء",
    "Livrer": "تسليم",
    "Annuler": "إلغاء",
    "Préparation des Commandes": "تحضير الطلبيات  ",
    "Commandes Non Traitées": "طلبيات حاضرة للتسليم ",
    "N° Commande": "رقم الطلبية ",
    "N° Cmd": "رقم الطلبية ",
    'N° Bl': 'رقم الفاتورة',

    "carton": "كرتون",
    "Carton": "كرتون",
    "date Commande": "تاريخ الطلبية",
    "date Livraison": "تاريخ التسليم",
    "Camion prévu pour la livraison": " الشاحنات المبرمجة لتسليم الطلبيات",
    "Camion de livraison": "شاحنة التسليم",
    "Visites": "الزيارات ",
    "Tickets et Remise": "التذاكر والخصم",
    "Tickets Cadeaux": "تذاكر الهدايا",
    "montant": "المبلغ",
    "Remise Espece Commande": "الخصم النقدي لطلبيات ",
    "Succé": "نجاح",
    "Remise ajouté avec succé": "تم إضافة التخفيض بنجاح ",
    "Succé": "نجاح",
    "ticket ajouté avec succé": "تم إضافة تذكرة الهدية بنجاح ",
    "Parametrage": "إعدادات",
    "Activites": "الأنشطة",
    "Categories": "الفئات",
    "Code": "الرمز",
    "Libelle": "الاسم",
    "Action": "النشاط",
    "Activité modifiée avec Succès": "تم تعديل النشاط بنجاح ",
    "Voulez vous vraiment supprimer cette activité!": "هل أنت متأكد أنك تريد حذف هذا النشاط!",
    "Supprimer l'activité": "حذف النشاط ",
    "Ajouter activite": "إضافة نشاط ",
    "Libelle": "الاسم",
    "Ajouter": "إضافة",
    "Catégorie modifiée avec Succès": "تم تعديل الفئة بنجاح",
    "Ajouter categorie": "إضافة فئة",
    "Tarification": "التسعير",
    "Encours par Commercial par Client": "الائتمان الجاري للمسوق ",
    "Remise Client": "تخفيض الحريف",
    "Correction Solde Client": "تعديل رصيد العميل",


    //Users
    "Connexion": "متصل ",
    "Equipement modifié avec Succès": " تم التعديل بنجاح",
    "Recherche": "بحث",
    "Modification terminée avec succès": " تم التعديل بنجاح",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فسخ الدور ! ",
    "Autorisations rôles": " الصلاحيات ",
    "Chargements": " شحن ",
    "Modifier chargements": "تعديل الشحن",
    "Demande de chargements": " طلب شحن ",
    "Détails des demandes de chargements": " تفاصيل طلب الشحن ",
    "Chargement type": "شحن نموذجي ",
    "Consultation des commandes": " فحص الطلبية ",
    "Banques": "بنك",
    "Export Bl+Avoir": " تحميل المبيعات و الرجوع ",
    "Ajout caisse": " إضافة حساب ",
    "Clôture caisse": " غلق حساب ",
    "Modification caisse": " تعديل حساب ",
    "Suppression caisse": " شطب حساب ",
    "Quota": " حد أقصى",
    "Ventes": " المبيعات ",
    "Liste Bls": " قائمة الفاتورات",
    "Détails des bls": " تفاصيل الفاتورة ",
    "Recouvrements": "استخلاص ديون ",
    "Bl facturé": "فاتورة",
    "Liste users": " قائمة المستخدمين ",
    "Ajout user": " إضافة مستخدم ",
    "Modification user": " تعديل المستخدم ",
    "Suppression user": " شطب مستخدم ",
    "Blocage user": " حذر مستخدم ",
    "Affectation gamme user": "تخصيص المجموعات للمستخدمين ",
    "Listes des rôles": " قائمة الأدوار ",
    "Ajout rôle": "إضافة دور ",
    "Suppression rôle": "شطب دور ",
    "Liste clients": "قائمة الزبائن ",
    "Modification client": "تعديل الزبون",
    "Suppression client": "شطب الزبون ",
    "Liste produits": " قائمة المنتجات ",
    "Ajout produit": " إضافة منتوج ",
    "Modification produit": "تعديل المنتوج ",
    "Suppression produit": " شطب المنتوج ",
    "Suivi routings": " المتابعة",
    "Zoning": "التقسيم",
    "Merchandiser": "مرشنديسر",
    "Modification terminée avec succès": "تم التعديل بنجاح",
    "Ajout effectué avec succès": "تمت الاضافة  بنجاح",
    "Paramétrages": "التعديلات",
    "Agent de recouvrement": "جامع الديون",
    "Modification terminée avec succès": "تم التعديل بنجاح",
    "Voulez-vous vraiment supprimer cet élément?": " هل تريد شطب المستخدم ",
    "Erreur vous ne pouvez pas supprimer cet élément!": "لا تستطيع شطب هذا المستخدم ",
    //"Attention!": " إنتبه ",
    "Recherche": "بحث ",
    "Photo Bl Avoir": " صورة فاتورة / إرجاع ",
    "Retour sans chargement": " رجوع بدون شحن ",
    "Insertion Mobile Double": " تكرار رقم الهاتف ",
    "Edition Avoir": " تعديل الإرجاع  ",
    "Fiche Article": "  بطاقة المنتوج ",
    "Accept et Refus total chargement": "  قبول أو رفض الشحن كامل ",
    "Ajout commande hors client": "إضافة الطلبية خارج الزبون ",
    "Recouvrement Total": " إستخلاص كل الديون ",
    "Obligation GPS": " ضرورة GPS ",
    "Impression_recette": "طباعة المداخيل  ",
    "Modification_mobile": " تعديل رقم الهاتف ",
    "Retenue": "خصم من المورد",
    "Affichage liste prix": "عرض قائمة الأسعار ",
    "Affichage HT": "عرض الضريبة",
    "Quota commandes": "الحد الأقصى للطلبية ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": " هل تريد شطب هذا المزود ! ",
    "Ajout effectué avec succès": " تمت إضافة المزود بنجاح ",
    "Suppression effectuée avec succès": " تم شطب المزود بنجاح ",
    "Modification terminée avec succès": "  تم التعديل بنجاح ",
    "Confirmation annulation entête": "إلغاء رأس الصفحة",
    "Registre de commerce": " سجل تجاري",
    "Code postale": " الترقيم البريدي ",
    "Contrat": " العقد ",
    "Commande stock": " مخزون الطلبيات ",
    "Ajout gratuités": "  إضافة مجانية ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Ajout effectué avec succès": " تمت إضافة الموزع بنجاح ",
    "Suppression effectuée avec succès": "  تم شطب الموزع بنجاح ",
    "Disponible pour la livraison": " جاهز للتسليم ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": " هل تريد شطب هذا المستودع ",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح",
    "Suppression effectuée avec succès": "تم الشطب بنجاح   ",
    "Equipements": "المعدات",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح ",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "Affectation terminée avec succès": "الانخراط في مهام المسوقين ",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "Erreur vous ne pouvez pas supprimer cet élément! ": "لا يمكنك الشطب ",
    "Blocage List Client": "حجب قائمة العملاء",
    //produits 
    "Tarification": "التعريف",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "prix_vente_public": "التسعيرة للعموم  ",
    "prix_promotion": "سعر التخفيضات",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Prix promotionnel": "سعر التخفيضات",
    "Prix d’achat HT": "سعر الشراء باستثناء الضرائب ",
    "Prix d’achat TTC": "سعر الشراء بإحتساب الضرائب ",
    "Soumis remise": "يخضع لتخفيضات ",
    "Soumis Gratuite": "يخضع للمجانيات ",
    "Soumis retenue": "خاضع لخصم من المورد ",
    "Hors vente": "غير قابل للبيع ",
    "Ordre": "ترتيب ",
    "Fiche article": "بطاقة المنتوج ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Erreur": "إنتبه ",
    "Erreur vous ne pouvez pas supprimer cet élément!": "لا يمكنك الشطب",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": " تم الشطب بنجاح  ",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Suppression effectuée avec succès": "تم الشطب بنجاح",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    "Modification terminée avec succès": "تم التعديل بنجاح ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": "  تم الشطب بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح  ",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح  ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Modification terminée avec succès": "تم التعديل بنجاح",
    "Groupe": "مجموعة",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح  ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": "هل تريد فعلا الشطب ؟",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح  ",
    "Suppression effectuée avec succès": " تم الشطب بنجاح ",
    "Charges": " المصاريف ",
    "Ajout d’une charge": "  إضافة كلفة  ",
    "Type de charge": "صنف الكلفة ",
    "Commercial": "المسوق",
    "Livreur": "رجل التسليم",

    "Analyse charge": "تحاليل التكاليف ",
    "Compte d’exploitation mensuel / van": "حساب التشغيل",

    "CA brut": "رقم المعاملات",
    " Remises": " التخفيضات ",
    "CA net ": "إجمالي الارادات ",
    "Achats consommés": "المشتريات ",
    "Marge commercial brute": "هامش الربح ",
    "Dépenses de publicités & trades": "تكاليف التسويق والاشهار",
    "Marge commercial nette": "صافي هامش الربح",

    "Salaires et charges connexes": "الرواتب الشهرية   ",
    "Frais de téléphone": "مصاريف شحن الهاتف  ",
    "Charges pour force de vente": "كلفة المسوقين ",
    "Dotation aux amortissements": "إستهلاك الدين",

    "Charge d’intérêts": "كلفة المصالح ",
    "chargement ajoutée avec Succès": "تم التحميل بنجاح",
    "Carburant": "الوقود ",
    "Accepté": "مقبول ",
    "Refusé": "مرفوض ",
    "Mixte": "مختلط ",
    "Non traité": "غير معالج ",
    "Valeur Stock": "قيمة المخزون  ",
    "Commission": "عمولة ",
    "Entretien": "الصيانة ",
    "Remise ajouté avec succé": "تم الاضافة بنجاح  ",
    "ticket modifié avec succé": "تم التعديل بنجاح ",
    "Voulez vous vraiment bloquer ce client!": "هل أنت متأكد أنك تريد حظر هذا العميل ",
    "Suppression avec succé": "تم الحذف بنجاح ",
    "Entretien & réparations": "الصيانة  والإصلاح ",
    "Assurance véhicule": " تأمين وسائل النقل  ",
    " Taxes sur les véhicules": " ضريبة وسائل النقل  ",
    "Frais visites auto": " معاينة العربة ",
    " Charges des matériaux roulants ": "تكاليف ",
    "Charges du matériel informatique": " مصاريف أجهزة الإعلامية ",
    "Fournitures consommables pour amortissements": "حساب الاستهلاك",
    "Etat TCL": "الحالة ",
    "Droit de timbre": " حق الطابع",
    "Autres charges": " مصاريف أخرى ",
    "Total coût de distribution": "تكلفة التوزيع ",
    "Dépenses": " المصاريف  ",
    "Résultat d’exploitation": " نتيجة الإستغلال  ",
    "Nv objectifs": " هدف جديد ",
    "N° object": " رقم الهدف ",
    "Mois": " الشهر ",
    "Semaine": " الاسبوع  ",
    "Sous Famille": " عائلة فرعية ",
    "Tx de vente": " نسبة المبيعات ",
    "DN stable": " تغطية رقمية قارة ",
    "Validation des objectifs": " تأييد الأهداف  ",
    "Recrutement": "انتدابات ",
    "Modification terminée avec succès": " تم التعديل بنجاح ",
    "Voulez-vous vraiment supprimer cet élément?": " هل تريد شطب الشرط  ",
    "Suppression effectuée avec succès": "تم الشطب بنجاح ",
    "Ajout effectué avec succès": " تمت الاضافة بنجاح ",
    //STOCK : " المخزون  " ,
    "Correction effectuée avec succès": " الإصلاح تم بنجاح ",
    "Dépôt": " المستودع ",
    "Ajout inventaire": " إضافة جرد جديد ",
    "Emplacement": "الموقع",
    "Ecart": "الفارق ",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح ",
    "Il existe déjà un inventaire non clôturé": "هناك تحديث مخزون لم يغلق بعد  ",
    "Validation d’inventaire du:": " تأييد تحديث المخزون ",
    "Ajout effectué avec succès": " تم الشحن بنجاح",
    "Détails inventaire": "تفاصيل الجرد ",
    "Succès de validation": "تحديث المخزون بنجاح ",
    "Stock camion": "مخزون الشاحنة  ",
    "Todolist": "قائمة الأعمال ",
    "Merchandising": "الترويج",
    "Rapport visites": "تقارير الزيارات ",
    "Réalisation": "التحقيق",
    "Réalisation en valeur": "التحقيق بالقيمة ",
    "Réalisation en quantité": "التحقيق الكمي ",
    "Valeur": "القيمة",
    "Quantité": "الكمية ",
    "DN": " التغطية الرقمية  ",
    "Recrutement": " إنتداب ",

    "Conventions": " الاتفاقيات ",
    "Produits": "المنتجات ",
    "Choisir gamme": " إختيار المجموعة  ",
    "Choisir famille": " إختيار عائلة ",
    "Choisir sous famille": " إختيار عائلة فرعية ",
    "Choisir marque": " علامة",
    "Intervalle": "الفاصلة",
    "Assortiment par catégorie client": "التصنيف حسب فئة الحريف ",
    "Veuillez sélectionner au moins une catégorie ": " يجب إختيار صنف ",
    "Ajout effectué avec succès": "تمت الاضافة بنجاح ",
    "Recalculer": "إعادة الحساب",
    "en dinars": "بالدينار",
    "dinars": "دينار",
    "Tout les camions": "جميع الشاحنات",
    "voulez vous vraiment Valider les Commandes de": "هل تريد حقًا تأكيد طلبيات الشاحنة؟",
    "Voulez-vous vraiment annuler les commandes de camion": " هل تريد حقًا إلغاء طلبيات الشاحنة ",
    "Veuillez selectionner le depot si vous voulez generer un bon de chargement": "يرجى تحديد المستودع إذا كنت تريد إنشاء قسيمة تحميل",
    "Valider Commande": "تأكيد الطلب",
    "Commandes validée avec Succès": "تم تاكيد الطلبيات بنجاح",

    "Consultation Commande": "مراجعة الطلبيات",
    "Date d'envoi": "تاريخ الارسال",
    "Date Reception": "تاريخ الاستقبال",
    "Gajou Net": "قابية",
    "Commande en Casier": "طلبية بالصندوق",
    "Casier": "الصندوق",

    "Tout les camions": "كل الشاحنات",
    "Gajou Reel": "عدد القبيات الحقيقي",
    "Bl(s) N°": "فواتير",
    "veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives": "يرجى التحقق من الكميات الأكبر من الكميات المخزنة أو الكميات السلبية",
    "Vente par Gamme": "البيع حسب المجموعات",
    'Non Transformé en BL': 'لم تتحول إلي فاتورة',
    'Transformé en BL': 'فواتير',
    'Modification de chargement': 'تعديل التحميل',
    'Entrer votre mot de passe': 'أدخل كلمة المرور',
    'accepted': 'مقبول',
    'refused': 'مرفوض',
    'chargement modifiée avec Succès': 'تم تعديل التحميل بنجاح',
    "Vous n'êtes pas autorisé": 'غير مسموح لك بالقيام بهذه العملية',
    "Important": "مهم",
    "Avant de changer le statut de accepted a refused, veuillez bien cliquer sur l'icone (purge) dans le téléphone correspendant": "قبل تغير التحميل من مقبول الى مرفوض الرجاء الضغط على (تطهير) في هاتف المسوق المطابق",
    "Entrer le mot de passe de controlleur": "أدخل كلمة سر المدقق",
    "Controlleur": `المدقق`,
    "Vérifier les quantités négatives": "الرجاء التحقق من الكميات السلبية",
    "Créer un mouvement de stock": "يؤثر على المخزون",
    "Bl Non livré": 'فواتير لم يتم تسليمها',
    "Quantité en pièce": 'كميات بالقطعة',
    "Quantité en carton": 'كميات بالصندوق',
    "Valorisé achat": "قيمة الشراء",
    "Valorisé vente": "قيمة البيع",
    "Voulez-vous vraiment supprimer cet inventaire": "هل أنت متأكد أنك تريد حذف هذا الجرد",
    "Inventaire supprimée avec Succès": "تم حذف الجرد بنجاح",
    "Valider l'inventaire": "تأكيد الجرد",
    "Inventaire validé avec succès": "تم تأكيد الجرد بنجاح",
    "ré-envoyer": "إعادة إرسال",
    "voulez vous vraiment ré-envoyer ce chargement!": "هل تريد حقًا إعادة إرسال هذا التحمل!",
    "Liste Retour": "قائمة الرجوع",
    "Objectif modifiée avec Succès": "تم تغيير الهدف بنجاح",
    "Interdit d'écraser un ancien intervalle d'objectif": "ممنوع الكتابة فوق الفاصل الزمني للهدف",

    "role modifié avec Succès": "تم تعديل الدور بنجاح",
    "Voulez vous vraiment supprimer ce role!": "هل أنت متأكد أنك تريد حذف هذا الدور!",
    "role Supprimé avec Succès": "تم حذف الدور بنجاح",
    "Vous ne pouvez pas supprimer ce role": "لا يمكنك حذف هذا الدور",
    "role ajouté avec Succès": "تمت إضافة الدور بنجاح",
    "responsable des ventes": "مسؤول المبيعات",
    "responsable_vente": "مسؤول المبيعات",

    "controle_chargement": "مراقبة التحميل والإرجاع",
    "Contrôleur de chargement": "مراقبة التحميل والإرجاع",
    "controle_stock": "مراقبة المخزون",
    "Contrôleur de stock": "مراقبة المخزون",
    "Liste des clients": "قائمة الزبائن",
    "n'afficher que mes données": "أظهر فقط البيانات الخاصة بي",
    "Pré commande": "الطلبيات",
    "Sans Paiement": "بدون خلاص",

    "Exporter vers serveur FTP": "ارسل عبر السرفير",
    "Envoyer par email": "ارسل عبر الإميل",
    "FTP": "بروتوكول نقل الملفات",

    "Vente comptoir": "المبيعات الرئيسية",
    "Modifié le": "تاريخ التعديل",
    "Plafond client": "سقف الزبون",
    "Mouvements des clients": "تحركات الزبائن",
    "Mouvement clients": "تحركات الزبائن",
    "commercial/chauffeur": "مسوق / سائق",
    "Sens": "اتجاه الكتابة",
    "Fonction": "الوظيفة",
    "Credit GMS": "الائتمان للمتاجر الكبيرة والمتوسطة",
    'Stock en piéce': "المخزون بالقطعة",
    'Stock en casier': "المخزون بالصندوق",

    'Total quantite': "الكمية الإجمالية",
    "Total carton": "مجموع الصناديق",
    "Heure de livraison": "وقت التسليم",
    "Heure de sortie du camion": "وقت خروج الشاحنة",
    "Poids": 'الوزن',
    'g': 'غ',
    'Kg': 'كغ',
    'T': 'طن',
    'Vos étes sur le point de supprimer le bl ainsi que leurs mouvements, visites et paiements': 'أنت على وشك حذف الفاتورة وتحركاتها وزياراتها ودفعاتها',
    'mot de passe invalide': 'كلمة المرور غير صحيحة',
    'erreur de chargement des données': 'خطأ في تحميل البيانات',
    'En Attente': 'في الانتظار',
    'Type de papier': 'نوع الورق',
    'Crédit de cette BL': 'الائتمان لهذه  الفاتورة',

    'Crédit Client': 'ائتمان الزبون',
    'Crédit Camion': 'ائتمان الشاحنة',
    'Total Crédit Client': 'إجمالي ائتمان الزبون',
    'Total Crédit Camion': 'إجمالي ائتمان الشاحنة',
    'Plafond Client': 'سقف ائتمان الزبون',
    'Plafond Camion': 'سقف ائتمان الشاحنة',
    'Crédit dépasse le plafond': 'يتجاوز الائتمان الحد الأقصى',
    'Vente par mois/Année': 'البيع بالشهر / السنة',
    'Valeur demandée': 'القيمة المطلوبة',
    'Valeur restée': 'القيمة المتبقية',
    'façon de calcule': "طريقة الحساب",
    "se calcule en chargement et en bilan journalier (stat-camion)": " يتم حسابه في التحميل والحساب اليومي -> تقرير إحصائيات الباعة",
    "ne se calcule pas en chargement et en bilan journalier (stat-camion)": "لا يتم احتسابه في التحميل والحساب اليومي -> تقرير إحصائيات الباعة",
    "ne se calcule pas en chargement et se calcule en bilan journalier (stat-camion)": "لا يتم حسابه عند التحميل ويتم حسابه في الحساب اليومي -> تقرير إحصائيات الباعة",
    "Info User": "معلومات المستخدم",
    "Détails User": "تفاصيل المستخدم",
    "Parametrage User": "إعدادات المستخدم",
    "Info Dépot": "معلومات الشاحنة",
    "Parametrage Dépot": "إعدادات الشاحنة",
    "Affectation Gammes": "تعيين المجموعات",
    "Affectation Commercials": "تعيين المستخدمين",
    "Affectation Sous Societes": "تعيين الشركات الفرعية",
    "Blocage commande": "حظر الفواتير",
    "Vente par crédit": "بيع بالائتمان",
    "Blocage liste clients": "حظر قائمة العملاء",
    "Délivré le": "صدر في",
    "Salaire Base": "الراتب الأساسي",
    "Type contrat": "نوع العقد",
    "CDD": "CDD",
    "CDI": "CDI",
    "Gerant": "مدير",
    "Patente": "براءات الاختراع",
    "Date d'embauche": "تاريخ التوظيف",
    "Commerciaux": "المندوبين",
    "CNSS": "CNSS",
    "Nbr d'enfant": "عدد الاطفال",
    "Permis": "رخصة القيادة",
    "Date de livrance": "تاريخ التسليم",
    "Remise facture avec paiement": "الخصم في الفاتورة  مع الدفع",
    "Choix Remise par commercial": " اختيار الخصم مع المسوق",
    'Par_dates': 'حسب الموعد',
    'Par dates': 'حسب الموعد',
    'Affichage par commercial': 'حسب الموزع',
    'Affichage par sous société': 'حسب الشركة الفرعية',
    'Clients Non visitée': 'الزبائن الذين لم تتم زيارتهم',
    'Total Client': 'إجمالي الزبائن',
    'Crédit par commerciaux et clients': 'الائتمان من قبل مندوبي المبيعات والعملاء',
    'Crédit par clients': 'الائتمان من قبل العملاء',
    'Tournée Ajoutée avec succès': 'تمت إضافة الجولة بنجاح',
    'Demande augmentation Plafond crédit exceptionnelle': '  طلب زيادة سقف ائتماني استثنائي ',
    'Dernier visite commercial': 'آخر زيارة لهذا المسوق',
    'Dernier visite client': 'آخر زيارة لهذا الزبون',
    'Aucune image': 'لا توجد صورة',
    'Affecter soussociete': 'تعيين شركة فرعية',


    'Authorization roles': 'تراخيص الأدوار ',
    'Manuel Nomadis': 'كتيب إستعمال نوماديس',
    'Power BI': 'باور بي آي',
    'Notification': 'إشعار',
    'Parametrage commandes': 'إعدادات الطلبيات',
    'Parametrage details commande': 'إعدادات تفاصيل الطلبيات ',
    'Echeances': 'تاريخ الاستحقاق',
    'Nv Clients': 'زبون جديد',
    'Demande avoir': 'طلب إسترجاع بضائع',
    'Détails Avoir': 'تفاصيل الإسترجاع',
    'Credit encours': 'الائتمان الجاري ',
    'Visite Hors Client': 'الزيارات الغير مبرمجة',
    'Modifier Chargements': 'تعديل الشحن',
    'Demande Des chargements': 'طلب الشحن',
    'Detail Demande Chargement': 'تفاصيل طلبيات الشحن',
    'Exporter bl+avoir': 'إستخراج الفاتورة + الاسترجاع  ',
    'Ajouter Caisse': 'إضافة صندوق ',
    'Cloture Caisse': 'إغلاق الصندوق ',
    'Modifier Caisse': 'تعديل الصندوق  ',
    'Supprimer Caisse': 'حذف الصندوق ',
    'Détails Achat': 'تفاصيل الشراء ',
    'Détail Bl': 'تفاصيل الفاتورة ',
    'Ajouter Bl': 'إضافة فاتورة ',
    'Liste des commandes': 'قائمة الطلبيات',
    'Détail Commande': 'تفاصيل الطلبيات',
    'Liste des factures': 'قائمة الفاتورة',
    'Liste des recouvrements': 'قائمة إسترجاع الديون  ',
    'details recouvrement': 'تفاصيل إسترجاع الديون  ',
    'Liste des BL Facturé': 'قائمة الفاتورات المفوترة',
    'Liste des Avoir': 'قائمة إسترجاع البضاعة ',
    'Détail avoir': 'تفاصيل الإسترجاع ',
    'Ajouter avoir': 'إضافة إسترجاع بضاعة ',
    'Liste Visites': 'قائمة الزيارات ',
    'Liste User': 'قائمة المستخدمين',
    'Liste Roles': 'قائمة الأدوار',
    'Ajouter User': 'إضافة مستخدم',
    'Modifier User': 'تعديل مستخدم',
    'Supprimer Client': 'حذف زبون ',
    'Supprimer User': 'حذف مستخدم ',
    'Supprimer Role': ' حذف دور',
    'Bloquer User': ' تجميد مستخدم',

    'Historiques Client': 'سجل العميل',
    'Tickets cadeaux & remise': 'تذاكر الهدايا والتخفيض',
    'Equipement Client': 'معدات الزبائن',
    'EncoursMarque Client': 'التأمين الجاري للماركة بالنسبة للزبون',
    'Mouvements Client': 'حركات الزبائن  ',
    'Parametrage Client': 'إعدادات الزبائن',
    'Liste Produits': 'قائمة المنتجات ',
    'Ajouter Produit': 'إضافة منتوج  ',
    'Modifier Produit': 'تعديل المنتج ',
    'Supprimer Produit': 'حذف  المنتج',
    'Suivi Routing': 'مراقبة الجولات',
    'Zonning': 'المناطق',

    'Signature client': 'توقيع العميل',
    'Catalogue pdf': 'ملف',
    'Signature': 'الامضاء',
    'Maps': 'الخريطة',
    'Map Vision': 'رؤية الخريطة',
    'commandes livrées non traité': 'طلبيات خرجت ولم تسلم بعد',
    'Voulez vous Rattraper ces commandes au camion': 'هل تريد إلحاق هذه الطلبيات إلى الشاحنة',
    'Réinitialisation': 'إعادة تعيين',
    'voulez vous vraiment Réinitialiser la Commande N°': 'هل تريد حقًا إعادة تعيين الطلبية رقم ',
    'Tx Vente': 'نسبة البيع',
    'Tx Visite': 'سبة الزيارات',
    'Clients sans position GPS': 'زبائن بدون موقع جغرافي',
    'Clients inactif': 'زبائن غير نشطين',
    'Clients bloqué': 'حظر الزبائن',
    'Clients sans tournée': 'زبائن بدون جولة',
    'inActif': 'زبائن غير فعالة',
    'Non Bloquer': 'غير مجمدة',
    'Bl annulé avec succès': 'تم إلغاء الفاتورة بنجاح',
    'Bl livré avec succès': 'تم تسليم الفاتورة بنجاح',
    'Vous avez deja des paiements pour cette BL': 'لديك بالفعل مدفوعات لهذه الفاتورة',
    "passer a l'état livré": 'انتقل إلى مرحلة التسليم',
    "Aucun paiement n'existe pour ce BL": 'لا يوجد دفع لهذه الفاتورة',
    "Pour passer le BL en crédit laisser tous les champs vides": 'لتمرير الفاتورة إلى الائتمان اترك جميع الحقول فارغة',
    "Payer et passer a l'état livré": 'ادفع وانتقل إلى مرحلة التسليم',
    "Valider le paiement": 'تأكيد الدفع و التسليم',
    "Mot de passe Obligatoire": 'كلمة المرور  ضرورية',
    "Avoirs non affecté": "ترجيع غير معين",
    "Après la validation il faut faire la synchronisation de téléphone": "بعد التحقق من الصحة يجب عليك مزامنة الهاتف",
    "Client modifié avec succès": 'تم تعديل العميل بنجاح',
    "image Client": 'صورة العميل',
    "image Visite": 'صورة الزيارة',
    "Vous êtes sur le point de": 'أنت على وشك',
    "Changer": ' تعويض هذه',
    "par": 'بهذه',
    'Liste des retours': 'قائمة الترجيع',
    "Produit hors stock ou quantité gratuite supérieur à quantité de stock": "المنتوج  غير موجود في المخزون أو الكمية المجانية أكبر من كمية المخزون",
    "Vous avez plusieurs produits gratuits": "لديك العديد من المنتجات المجانية",
    "cliquez ici": "انقر هنا",
    "pour sélectionner": "لتحديدها",
    "Avant d'imprimer vérifier la destination": 'قبل الطباعة تحقق من الوجهة',
    "Valeur de promotion": "مع التخفيض",
    "Valeur de gratuites": "مع المجانية",
    "Vente sans promotion": "بدون تخفيض",
    "Historique de correction stock Depot": "أثر وتاريخ المخزون",
    "Ancienne quantité": "الكمية القديمة",
    "Nouveau quantité": "الكمية الجديدة",
    "Code Produit": "رمز المنتوج",
    "Augmentation": "زيادة",
    "Diminution": "إنخفاض",
    "Clients programmés non visité": "الزبائن اللذين لم تتم زيارتهم",
    "clients non visité": "العملاء الذين لم تتم زيارتها",
    "Date prévu pour la livraison": "التاريخ المتوقع للتسليم",
    "Transformer en bon de livraison": "تحويل إلى فاتورة",
    "Choix imprimante": "اختيار الطابعة",
    "Afficher la liste des produits": "عرض قائمة المنتجات",
    "Numero piece": "رقم الوثيقة",
    "avec timbre": "مع الطابع",
    "Valeur timbre": "قيمة الطابع ",
    "Choix Catégorie client": "خيار فئة العميل ",
    "Valider la transformation de cette commande en Bon de livraison": "تحويل الطلبية إلى فاتورة",
    "Voulez vous envoyer vers le terminal du livreur": "هل تريد إرسالها إلى  هاتف المسوق",
    "Date prévu de livraison": "تاريخ التسليم المتوقع",
    "Voulez-vous vraiment Annuler cette commande": "هل أنت متأكد أنك تريد إلغاء هذه الطلبية",
    "Dépots sans tournees": " شاحنات بدون جولات ",
    "Global societe": " الشركات ",
    "Tx.D": "نسبة التوزيع",
    "DN.G": "التوزيع الرقمي العام ",
    "Credit en cours": "الإئتمان الجاري",
    "Kilometrage": "المسافه المقطوعه",
    "Fermer Recherche Client": " غلق البحث عن العملاء",
    "Obligation Photo": " التزام الصورة ",
    "Obligation GPS": " التزام GPS ",
    "Valeur Tolerance": " قيمة التسامح في الائتمان ",
    "Ajout Client dans tournée automatique": " إضافة الزبون في الطريق  آلي ",
    "Plafond demande de chargement": "القيمة القصوة لطلبيات التحميل",


    "_rapport1_": '*Rapport 1 : Total vente commercial par produit ( chiffre/qté/carton ) à une intervalle de temps limitée par une date de début et de fin , les modes de paiement( Esp , Chq , crédit ) le taux de vente et de succés, le nombre de clients visités et le nombre de nouveaux clients recruté .',
    "_rapport2_": '*Rapport 2 : Total vente pour chaque commercial ( chiffre/qte/carton ) à une intervalle de temps limitée par une date de début et de fin , les modes de paiement( Esp , CHQ , crédit ) le taux de vente et de succés , le nombre de clients visités et le nombre de nouveaux clients recruté .',
    "_rapport3_": '',
    "_rapport4_": '*Rapport 4 : KPI commercial ( ventes, crédit, recouvrement, nbr BL, nbr visites ... ) par jour , par mois et par année, details des tournées ( heure debut , heure fin , temps des travaux et temps hors travaux ... ) quotidien. clients programmés non visités et vente par produits',
    "_rapport5_": '*Rapport 5 : Vente par marque , gamme et famille ',
    "_rapport6_": '*Rapport 6 : Vente et DN par région',
    "_rapport7_": '*Rapport 7 : Vente periodique ( date debut, date fin ) par marque, famille, sous famille, gamme, produit et fournisseur ( carton , qte , valeur ) .',
    "_rapport8_": '*Rapport 8 : Vente periodique: par mois, année d\'une famille par commercial .',
    "_rapport9_": '*Rapport 9 : Vente famille ( qte, chiffre, pourcentage ) par gamme de produit.',
    "_rapport10_": '*Rapport 10 : Vente clients par date.',
    "_rapport11_": '*Rapport 11 : Pourcentage vente de chaque commercial pour chaque gamme de produit.',
    "_rapport12_": '*Rapport 12 : Vente par sous famille par client.',
    "_rapport13_": '*Rapport 13 : DN par produit, le pourcentage est calculé ainsi:( nbre de produit vendus par mois / nbre clients par mois ) ',
    "_rapport14_": '*Rapport 14 : DN par produit, le pourcentage est calculé ainsi:( nbr de produit vendus par mois / nbr clients par mois )par région ',
    "_rapport15_": '*Rapport 15 : (taux succès/taux de respect) pour chaque commercial ',
    "_rapport16_": '',
    "_rapport17_": '*Rapport 17 : Vente commercial: affiche le total des ventes en indiquant la date début et fin. ',
    "_rapport18_": '*Rapport 18 : Vente commercial: ( nbre de visites, nbre de Bl, nbre des BL payés et impayés, nbre des avoirs, des ventes, recettes, recouvrements ) ... ',
    "_rapport19_": '*Rapport 19 : KPI commande par gouvernerat.',
    "_rapport20_": '*Rapport 20 : Chiffre de vente par zone.',
    "_rapport21_": '*Rapport 21 : Vente total , nombre des BL total , nbre de visites total et qte total vendues et la classification par ordre croissant selon le montant de vente par ( gamme , famille , sous famille , marque et produit )',
    "_rapport22_": '*Rapport 22 : Vente par client ( gamme , famille , sous famille , marque ) par commercial et date.',
    "_rapport23_": '*Rapport 23 : Credit par commercial en affichant les coordonnées du client, la date de crédit et la route.',
    "_rapport24_": '*Rapport 24 : Consultation des crédits des clients',
    "_rapport25_": '*Rapport 25 : Credit par commercial en affichant les coordonnées des clients, la date de crédit et le total.',
    "_rapport26_": '',
    "_rapport27_": '',
    "_rapport28_": '',
    "_rapport29_": '',
    "_rapport30_": '',
    "_rapport31_": '',
    "_rapport32_": '',
    "_rapport33_": '',
    "_rapport34_": '',
    "_rapport35_": '',
    "_rapport36_": '',
    "_rapport37_": '',
    "_rapport38_": '',
    "_rapport39_": '',
    "_rapport40_": '',


    // DASHBOARD https://demo.clediss.online/
    "Obejectif Journalier": "الهدف اليومي",
    "Vente par Commercial/Gammes": "البيع حسب المندوب / المجموعة ",
    "Vente par Commercial/Marques": "البيع حسب المندوب / الماركات  ",
    // BILAN JOURNALIER https://demo.clediss.online/chargements/stat-chargements
    "Etat Casier": " حالة الصناديق ",
    "Retour hier": "ترجيع الأمس ",
    "RET/C": "ترجيع / مطابق ",
    "Conforme": "متوافق",
    "Code X3": "كود X3",
    'Ratio' : "نسبة",
    "CHARG/C": "تحميل مطابق ",
    "VENTE/P": "بيع بالقطعة ",
    "VENTE/C": "بيع مطابق",
    "CA/CASSE": "رقم المعاملات / كسر ",
    "REM": "الخصومات",
    "CA/DLC": "رقم المعاملات / إنتهاء الصلوحية ",
    "ECART MOIS": "الفرق شهرياً ",
    "Crédit gms & Gazerne": "الائتمان GMS / ثكنة ",
    "STE" : "موقع",
    "CA/GRATUITE": "رقم المعاملات / المجانية ",
    "التنظيم & crédit": "التنظيم والائتمان ",
    "Gazoil": "بنزين",
    "Entretien": "صيانة",
    "Carburant": "بنزين",
    "ESP Divers": "نقداً",
    "Depense": "مصاريف",
    "Commentaire": "تعليق ",
    //https://demo.clediss.online/caisses/
    "Crée le": "أنشأ",
    //https://demo.clediss.online/chargements/stat-vente
    "Stat Vente": "إحصاءات المبيعات",
    "Vente Réelle - Gratuite - Vente Facture CL": "بيع حقيقي - مجاني - بيع بفاتورة CL",
    "Vente Facture CL": "فاتورة البيع CL",
    "Gratuite": "المجانية ",
    "Dlc + Casse": "إنتهاء صلوحية + كسر ",
    "Excel Vente": "Excel البيع",
    "Export factures CL": "تصدير فواتير CL",
    "Export factures Passager": "تصدير فاتورات الزبائن",
    //https://demo.clediss.online/chargements/stat-vente-commercials
    "Excel Vente": "Excel البيع",
    "Export factures CL": "تصدير فواتير CL",
    "Export factures Passager": "تصدير فاتورات الزبائن",
    //https://demo.clediss.online/chargements/
    "Valeur Stock": "قيمة المخزون  ",
    "Total Caisse": "إجمالي الخزينة",
    //https://demo.clediss.online/chargements/liste
    "Date chargement": "تاريخ التحميل ",
    //https://demo.clediss.online/clients/ajouter
    "Adresse de Livraison": "عنوان التسليم",
    "Autorisation cadeaux": "ترخيص هدية ",
    //https://demo.clediss.online/clients/parametre
    "Parametrage": "إعدادات",
    "Activites": "أنشطة",
    "Categories": "صنف ",
    "Code": "رمز ",
    "Libelle": "إسم ",
    "Action": "عمل",
    "Ajouter activite": "إضافة نشاط ",
    "Code": "رمز",
    "Libelle": "إسم ",
    "Action": "عمل",
    "Ajouter categorie": "إضافة صنف ",
    "Libelle": "إسم ",
    "Tarification": "التعريفة ",
    "Ajouter": "إضافة ",
    //https://demo.clediss.online/cloturebls/    
    "Cloture bls": "إغلاق فاتورات ",
    //https://demo.clediss.online/cadeaux/
    "Ajouter Cadeaux": "إضافة هدية ",
    //https://demo.clediss.online/commandes///
    "Grossiste": "تاجر الجملة",
    // "Validé": "",
    // "Non Validé": "",
    //https://demo.clediss.online/plandecharges///
    "Plan de charge": "خطة التحميل",
    //https://demo.clediss.online/visites/
    "Clients": "الزبائن ",
    "Visites": "الزيارات ",
    //https://demo.clediss.online/routings/
    "Tournees & Layers Map": "جولات وخريطة الطبقات",
    //https://demo.clediss.online/users/list
    "Configuration des Emails automatiques": "إعدادات البريد الالكتروني اتوماتيكياً ",
    "Envoyer les rapports journalier à ": "إرسال التقارير اليومية إلى",
    "Envoyer les commandes à": "أرسل الطلبات إلى",
    "Envoyer les Bls à": "إرسال الفاتورة إلى ",
    "Emails": "البريد إلكتروني ",
    //https://demo.clediss.online/depots/edit/
    "Plafond dc": "سقف طلب التحميل",
    "Modification Séquences": "إعدادات التسلسل ",
    //https://demo.clediss.online/equipements/
    "Equipement": "معدات",
    //https://demo.clediss.online/users/affectationsCommercialCamion
    "Affectation equipe commercial": "تعيين فريق المبيعات",
    //https://demo.clediss.online/produits/
    "Augmentation prix": "زيادة الأسعار",
    //https://demo.clediss.online/produits/ajouter
    "Prix Achat HT*": "سعر الشراء غير شامل ضريبة القيمة المضافة",
    "Prix Achat TTC*": "سعر الشراء شاملاً ضريبة القيمة المضافة",
    "Soumis R*": "يخضع لتخفيض ",
    "Soumis Retenue": "تخضع لضريبة الاستقطاع",
    "Hors Vente": "خارج بيع ",
    //https://demo.clediss.online/rapports/
    "suivi vente client par type": "متابعة مبيعات العملاء حسب النوع",
    "Vente S/F/promo/gratuite": "بيع أسرة فرعية / برومو / مجانية  ",
    "Reporting Commercial": "تقرير العملاء ",
    "Reporting Visites/Users": "تقرير الزيارات / المستخدمين ",
    "Reporting Visites/Dates": "تقرير الزيارات / التواريخ ",
    "suivi Echantillons": "تتبع العينة",
    "Tournées/Region": "جولة / منطقة ",
    "Assortiments Relevé": "رفع المنتوجات",
    "Commercial / Client": "المندوب / الزبون ",
    "Cause non vente": "سبب عدم البيع",
    "Gratuites & accessoires": "الهدايا والإكسسوارات",
    "Tickets": "تذاكر",
    "Vente Hebdomadaire": "البيع الأسبوعي",
    //https://demo.clediss.online/charges/
    "Type Charge": "نوع الحمولة",
    "Commerial": "مندوب ",
    "Ajouter une charge": "أضف حمولة ",
    "Analyse": "تحليل ",
    "Type Charge": "صنف الحمولة ",
    //https://demo.clediss.online/charges/analyses
    "Analyse Charge": "تحليل الحمولة ",
    "Compte d expolatation Mensuel / Van": "حساب تداول شهري شاحنة",
    "CA Brut": "رقم المعاملات الاجمالي ",
    "Remises": "تخفيضات ",
    "CA NET": "صافي قيمة التداول",
    "Achats consommés": "مشتريات مستهلكة",
    "Marge Commercial Brut": "هامش التداول الإجمالي",
    "Dépences en publicité & trade": "مصاريف الإعلان والتجارة",
    "Marge Commercial Nette": "صافي الهامش التجاري",
    "Salaires & Charges Connexes": "الرواتب والرسوم ذات الصلة",
    "Frais de Téléphone": "رسوم الهاتف",
    "Charges dédiées à la force de vente": "المصروفات المخصصة لفريق المبيعات",
    "Dotation aux amortisement": "بدل الإهلاك",
    "Charge d interets": "",
    "Carburant": "الوقود",
    "Entretien et réparations": "الصيانة والتصليح",
    "Assurance véhicule": "تأمين السيارة",
    "Tax sur les véhicules": "ضريبة السيارة",
    "Frais visite auto": "تكاليف زيارة السيارة",
    "Charges dédiées au matériel roulant": "الأحمال المخصصة للعربات الدارجة",
    "Dotations aux amortissement": "مصاريف الاستهلاك",
    "Charges dédiées au matériel informatique": "المصروفات المخصصة لمعدات تكنولوجيا المعلومات",
    "Fournitures consommables aux amortissement": "التوريدات الاستهلاكية للإهلاك",
    "Etat TCL": "حالة TCL",
    "Droit de timbre": "حق الطابع",
    "Autres charges dédiées": "أحمال مخصصة أخرى",
    "Total cout de distribution direct": "إجمالي تكلفة التوزيع المباش",
    "Résultat d exploitation": "نتيجة الاستغلال",
    "Visites clients/Tournees": " زيارات/جولات العملاء  ",
    "Suivi Echantillons": "تتبع العينة",
    "Détails Vente": "تفاصيل البيع",
    "Ventes & commandes des commerciaux": "المبيعات والطلبات من البائعين",
    "Chiffre Vente": "رقم المبيعات",
    "Nbr Ligne Bl": "عدد المنتوجات في المبيعات",
    "Poids Vente": "وزن المبيعات",
    "Chiffre Commande": "رقم الطلبيات",
    "Poids Commande": "وزن الطلبيات",
    "Nbr Ligne Commande": "عدد المنتوجات في الطلبيات",
    "Ventes & commandes par Gammes/Familles/Sousfamilles": "المبيعات والطلبات حسب النطاقات/العائلات/العائلات الفرعية",
    "Ventes & commandes par Regions/Zones": "المبيعات والطلبات حسب المناطق/المناطق",
   
    //https://demo.clediss.online/depences/
    "depences": "نفقات",
    //https://demo.clediss.online/objectifs/
    "Objectif Annuel": "الهدف السنوي",
    "Objectif Annuel/Region": "الهدف السنوي / المنطقة",
    "Objectif Annuel/User": "الهدف السنوي / المستخدم",
    "Ajouter des objectifs Journalier": "إضافة أهداف يومية",
    "objectifs Journalier/Réalisation": "الأهداف اليومية / الإنجاز",
    "Date début": "تاريخ البداية",
    "Date fin": "تاريخ الانتهاء",
    "Recalculer les objectifs": "إعادة حساب الأهداف",
    "Recalculer les objectifs de": "إعادة حساب أهداف",
    "Selectionner tout": "اختر الكل",
    "Valider les objectifs": "اختر الكل",
    "Objectif S": "لأهداف",
    "Coef": "Coef",
    "User Validate Cadeaux": "Coef",
    //https://demo.clediss.online/mouvements/
    "Sous Societe": "شركة فرعية ",
    //https://demo.clediss.online/stockDepot/
    "Transferer": "نقل ",
    //https://demo.clediss.online/achat/
    "Trier par": "الترتيب حسب",
    "Code Paiement": "كود الدفع",
    "Date Saisie": "تاريخ دخول",
    "Date Facture": "تاريخ الفاتورة",
    "Document": "وثيقة",
    "Brut": "خام",
    "HT": "بدون ضرائب",
    "TTC": "جميع الضرائب متضمنة",
    "Code Paiement": "رمز الدفع",
    "Reglements": "قواعد",
    "Statistique": "إحصاء",
    "Mouvement Fournisseurs": "حركة الموردين",
    "Chéquier": "شيكات ",
    //https://demo.clediss.online/achat/reglements
    "Choisir": "اختار",
    "Libelle": "الصياغة",
    "Type": "صنف ",
    "Montant": "قيمة ",
    "Reste": "باقي ",
    "N°": "رقم",
    "Dates": "تواريخ",
    "Banque": "بنك",
    "Commentaire": "تعليق",
    "Total": "مجموع",
    "Date Début": "تاريخ البداية",
    "Date Fin": "تاريخ الانتهاء",
    "Trier par": "الترتيب حسب",
    "Code Paiement": "كود الدفع",
    "Info": "معلومات",
    "Document": "وثيقة",
    "Brut": "خام",
    "HT": "",
    "TTC": "",
    "Net a payer": "صافي للدفع",
    "Code Paiement": "كود الدفع",
    "Statistique": "إحصاء",
    "Mouvement Fournisseurs": "حركة الموردين",
    "Chéquier": "شيكات ",
    //https://demo.clediss.online/achat/statachat
    "Achat par mois": "شراء شهريا",
    "Encours par mois": "المعلقة شهريًا",
    "Chiffre HT Net": "رقم HT الصافي",
    "N° Pièce": "رقم القطعة",
    "Total HT Brut": "إجمالي HT",
    "Total HT Net": "إجمالي صافي HT",
    //https://demo.clediss.online/mouvement-fournisseurs/
    "Mouvement Fournisseurs": "حركة الموردين",
    "NB/Facture": "NB / الفاتورة",
    "Date Dernier Livraison": "آخر موعد للتسليم",
    "Reference Facture": "مرجع الفاتورة",
    "Disitributeur": "موزع",
    "Réf Facture": "فاتورة المرجع",
    "Ref Facture": "فاتورة المرجع",
    //https://demo.clediss.online/caisses/
    "date Modif": "تاريخ التعديل ",
    //https://demo.clediss.online/notes/
    "Theme": "سمة",
    "Vocal": "صوتي",
    //https://demo.clediss.online/realisation/
    "Valeur": "قيمة",
    "Quantité": "كمية",
    "DN": "التوزيع الرقمي ",
    "Recrutement": "إنتداب ",
   
    "Valeur": "قيمة",
    "Quantite": "كمية",
    "Commercial": "مندوب ",
    "Objectif": "هدف ",
    "Réalisé": "تحقق ",
    "Réalisation": "جاري التحقق ",
    //https://demo.clediss.online/assortiments/
    "Assortiment par Categorie": "تصنيف حسب الفئة",
    //https://demo.clediss.online/recensement/
    "Traveaux merchs": "أنشطة العملاء ",
    "Delegations & DN": "المناطق والتوزيع الرقمي ",
    "Agent": "مندوب ",
    "Fermé": "إغلاق ",
	
	
	// DASHBORD
	"Objectif Journalier":"الهدف اليومي",
	"Chiffre":"رقم",
	"Quantite":"كمية",
	"Colisage":"التعبئة",
	"Poids":"الوزن",
	//https://demo.clediss.online/chargements/stat-chargements
	"Interfaçage":"التواصل",
	"Commission/Marge":"العمولة / الهامش",
	"Stat Retour":"إحصائيات الترجيع",
	"J.commercial":"أيام العملاء ",
    "R.commercial":"تحصيلات المندوبين ",
	"Correction Prix chargement":"تصحيح سعر التحميل",
	"Par date":"حسب التاريخ",
	"Export Par date": "تصدير حسب التاريخ",
	//https://demo.clediss.online/chargements/
	"Retour instantané":"ترجيع السلع الفوري",
	"N° Lot":"رقم الدفعة",
	//https://demo.clediss.online/clients/ajouter
	"Categorie Statistique":"الفئة الإحصائية",
	//https://demo.clediss.online/clients/parametre
	"Tarification":"التسعير",
	//https://demo.clediss.online/devis-list///
	"Devis":"التسعير",
	//https://demo.clediss.online/visites/
	"Position client invalide":"موقع العميل غير صحيح ",
	"Position visite invalide": "موقع الزيارة  غير صحيح ",
	//https://demo.clediss.online/routings/
	"Tournees & Layers Maps":"جولات وخرائط الطبقات",
	//https://demo.clediss.online/parametrages/
	"Validation Bl* (ex: 72 H,24 H)":"التحقق من صحة سند التسليم (على سبيل المثال: 72 ساعة ، 24 ساعة)",
	"Validation cadeaux":"تأكيد الهدية ",
	"commentaire commande":"تعليق على الطلبية",
	"Affichage produit hors_stock":"عرض المنتج خارج المخزون",
	"Proposition commande bl":"إقتراح طلبية / فاتورة ",
	"Paiement android":"خلاص أندرويد ",
	"Impression decharge client":"طباعة إخلاء مسؤولية العميل",
	"Email_recap":"بريد إلكتروني",
    "Ajout gratuite par commercial":" إضافة مجانية عن طريق المندوب ",
    "Type remise":" نوع الخصم  ",
    "Impression et édition uniquement BL avoir":" فاتورة وترجيع فقط  ",
    "Impression prix sur le ticket":"طباعة السعر على التذكرة ",
    
	//https://demo.clediss.online/produits/
	"Télécharger le model d'import (vide)":"تنزيل نموذج الاستيراد (فارغ)",
	//https://demo.clediss.online/rapports/
	"Chargement/retour":"تحميل / ترجيع",
	"Taux demande CHargement":"نسبة طلب الحمولة",
	"Suivi Prospect":"المتابعة",
	"Prime CASH/TERME":"قسط النقدية / لأجل",
	//https://demo.clediss.online/charges/analyses
	"Compte d'expolatation Mensuel / Van":"حساب التشغيل الشهري / شاحنات ",
	"CA Brut":"إجمالي رقم المعاملات ",
	"Remises":"تخفيضات",
	"CA NET":"صافي قيمة التداول",
	"Achats consommés":"مشتريات مستهلكة",
	"Marge Commercial Brut":"الهامش التجاري الإجمالي",
	"Dépences en publicité & trade":"مصاريف الدعاية والتجارة",
	"Marge Commercial Nette":"صافي الهامش التجاري",
	"Salaires & Charges Connexes":"الرواتب والرسوم ذات الصلة",
	"Frais de Téléphone":"مصاريف الهاتف",
	"Charges dédiées à la force de vente":"المصروفات المخصصة للعملاء ",
	"Dotation aux amortisement":"مصاريف الاستهلاك",
	"Charge d'interets":"تكلفة الفائدة",
	"Carburant":"البنزين",
	"Entretien et réparations":"الصيانة والتصليح",
	"Assurance véhicule":"تأمين المركبة",
	"Tax sur les véhicules":"ضريبة السيارة",
	"Frais visite auto":"رسوم الزيارة الفنية للسيارات",
	"Charges dédiées au matériel roulant":"التكاليف المخصصة للعربات",
	"Dotations aux amortissement":"بدلات الإهلاك",
	"Charges dédiées au matériel informatique":"المصروفات المخصصة لمعدات تكنولوجيا المعلومات",
	"Fournitures consommables aux amortissement":"التوريدات الاستهلاكية للاستهلاك",
	"Etat TCL":"حالة",
	"Droit de timbre":"حق الطابع",
	"Autres charges dédiées":"رسوم أخرى مخصصة",
	"Total cout de distribution direct":"إجمالي تكلفة التوزيع المباشر",
	"Résultat d'exploitation":"نتيجة الاستغلال",
	//https://demo.clediss.online/charges/types
	"Type Charge":"نوع الحمولة",
	"Code":"رمز",
	"Libelle":"إسم ",
	"Centre Cout":"مركز التسعير",
	"Nombre de mois":"عدد الأشهر",
	"Action":"فعل",
	"Ajouter type charge":"أضف نوع الحمل",
	"Nombre de mois":"عدد الأشهر",
	//https://demo.clediss.online/objectifs/propositions
	"Propositions":"إقتراحات",
	"Date Début":"تاريخ البداية",
	"Date Fin":"تاريخ الانتهاء",
	"Type":"فئة",
	"Commercial":"المندوب",
	"Client":"عميل",
	"Ordre":"الترتيب",
	"Code":"رمز",
	"Produit":"منتج",
	"Colisage":"التعبئة",
	"Prix HT":"السعر بدون ضريبة",
	"Prix TTC":"السعر شامل جميع الضرائب",
	"Nbr Jours(vente)":"عدد الايام (بيع",
	"Total Quantite":"الكمية الإجمالية",
	"Total Chiffre":"الرقم الإجمالي",
	"Total Poids":"إجمالي الوزن",
	"Quantite":"كمية",
	"Box":"صندوق ",
	"Chiffre":"رقم",
	"Poids":"الوزن",
	//https://demo.clediss.online/stockDepot/historique-stocks-depots-par-inventaire
	"Date Hier (StockHier)":"التاريخ أمس (مخزون أمس)",
	"Date du dernier Stock (Stock Finale)":"(تاريخ آخر مخزون (المخزون النهائي",
	"Ordre":"الترتيب",
	"Code":"رمز",
	"Produit":"المنتج",
	"Caisse":"الصندوق التجاري",
	"Stock Hier":"مخزون أمس ",
	"Nbr Caisse":"عدد الصناديق التجارية",
	"Unite/CV":"وحدة ",
	"Stock AV Chargement":"المخزون قبل التحميل ",
	"Stock APR Chargement":"المخزون بعد التحميل",
	//https://demo.clediss.online/caisses/
	"cloturé le":"إغلاق",
	"Échange GMS":"تبادل GMS",
	//https://demo.clediss.online/stocks/
	"Aide Commercial":"مساعد مندوب",
	//https://demo.clediss.online/realisation/
	"Valeur":"القيمة",
	"Quantité":"الكمية",
	"DN":"التوزيع الرقمي",
	"Recrutement":"توظيف",
	"Recouvrement":"إسترداد الديون",
//	https://demo.clediss.online/tickets/
	"Résolu":"تم الحل",
	"commentaire":"تعليق",

    "Nbr Visites":" عدد الزيارات ",
	"Nbr Bls":"عدد الفواتير ",

    "Clôture journée":"نهاية يوم",
    "Toucher caisse Avoir":"إدخال الترجيع فألحساب",
    "Pousse a la vente":"الدفع للبيع",
    "Affichage produit chargé uniquement dans bilan":" عرض المنتج المحمل فقط في الحساب اليومي",
    "Re-envoyer le chargement":"إعادة إرسال التحميل",
	
	"SUPPORT":"الدعم",



    "Crédit gms":"ائتمان جي إم إس",
    "Collectivité":"مجتمع",
    "Collectivite depot":"مستودع المجتمع",
    "crédit":"ائتمان",
　　"Etat caisse":"الوضع النقدي",
　　//https://demo.clediss.online/clients/
    "Maps Clients":"خرائط العملاء",
　　"factures":"الفواتير",
　　//https://demo.clediss.online/factures///
　　"Ajouter Facture":"إضافة فاتورة",
　　//https://demo.clediss.online/kpi/
　　"Décembre":"ديسمبر",
　　//https://demo.clediss.online/produits/
　　"Télécharger le model d\'import (vide)":"نموذج الاستيراد (المنتج)",
　　//https://demo.clediss.online/mouvements/
　　"Rapport Movement par Type/Date":"تقرير الحركة حسب النوع/التاريخ",
　　//https://demo.clediss.online/stockDepot/
    "Stock inférieur ou égale à stock de sécurité":": مخزون أقل من أو يساوي مخزون السلامة",
    "Stocks / Chargements / inventaire":"المخزونات / التحميلات / المخزون",
　　"Historique de stock dépôt":"تاريخ مخزون المستودع",
　　//https://demo.clediss.online/achat/
　　"Date saisie":"تاريخ دخول",
　　//https://demo.clediss.online/caisses/
　　"Extrait caisse":"مستخلص نقدي",
　　//https://demo.clediss.online/notes/
    "VEILLE CONCURRENTIELLE":"الاستخبارات التنافسية",
    "RECLAMATION":"مطالبة",
　　"INFORMATION":"معلومة",
　　//https://demo.clediss.online/stocks/
    "validite":"صلاحية",
     //https://demo.clediss.online/integrationerp/
    "Mouvement Clients":"حركة العملاء",
    "Demande de chargement":"طلب التحميل",
    "Integration Standard NOMADIS":"معيار التكامل NOMADIS",
    "Télécharger le modele":"قم بتنزيل النموذج",
    "Export produits":"تصدير المنتجات",
    "Export familles":"تصدير العائلات",
　　"Export lignes":"خطوط التصدير",
　　//"https://demo.clediss.online/realisation/"
　　"Réalisation en Valeur":"تحقيق القيمة",
　　//https://demo.clediss.online/realisation/
    "Valeur":"قيمة",
    "Quantité":"كمية",
    "Recrutements":"التوظيف",
    "Recouvrements":"المبالغ المستردة",
    "Réalisé":"مُتَفَوِّق",
    "Réalisation":"انجاز",
    "Objectif":"هدف",
    "Total":"مجموع",
　　
　　//https://demo.clediss.online/caisses/etatcaisse
    "Date":"تاريخ",
    "Distributeur":"الموزع",
    "Liste chargements":"جارٍ تحميل القائمة",
    "Liste retours":"قائمة الإرجاعات",
    "Liste dlc":"قائمة DLC",
    "Liste casse":"قائمة الحالة",
    "Initiale":"مبدئي",
    "Chargement Caisse":"تحميل الخروج",
    "Retour Caisse":"إرجاع الخروج",
    "Casse Caisse":"أمين الصندوق",
    "Finale":"أخير",
    "Chargement Caisse PM":"جارٍ تحميل أمين الصندوق PM",
    "Retour Caisse PM":"عودة الخروج PM",
    "Chargement Palette":"تحميل الباليتات",
    "Petit casier":"خزانة صغيرة",
    "Gros casier":"خزانة كبيرة",
    "Chargement Petit casier":"جارٍ التحميل خزانة صغيرة",
    "Retour Petit casier":"العودة خزانة صغيرة",
//https://demo.clediss.online/calendar/
    "Calendrier":"تقويم",
    "Chéques : (commercial->client (Montant) )":"الشيكات: (التجارية->العميل (المبلغ))",
    "Evenement : (commercial->client (Temps) )":"الحدث: (تجاري->العميل (الوقت))",
    "week":"الأسبوع",
    "Day":"اليوم",
    "month":"الشهر",
    "Today":"اليوم",
    "next":"التالي",
    "prev":"السابق",
    "Sun":"الاحد",
    "Mon":"الاثنين",
    "Tue":"الثلاثاء",
    "Wed":"الاربعاء",
    "thu":"الخميس",
    "Fri":"الجمعة",
    "Sat":"السبت",
    //https://demo.clediss.online/calendar/
    "Recensement":"التعداد",
    "Succès Action effectuée avec succès":"نجاح اكتمل الإجراء بنجاح",
    "mise à jour nombre des clients":"تحديث عدد العملاء",
    "Fréquence":"التردد",
    //https://demo.clediss.online/cadeaux/cadeaux-non-valide
    "Liste Cadeaux non valider":"قائمة الهدايا غير المعتمدة",
    "Filtre par fournisseurs":"التصفية حسب الموردين:",
    "Filtre par Distributeur":"التصفية حسب الموزع:",
    "Factures":"الفواتير",
    "cadeaux":" هدية",
    "non validé":" غير مفعلة ",
    "Liste chargements":"جارٍ تحميل القائمة",
    "Il est interdit de retourner une facture dont la date d expiration reste":" ممنوع إسترجاع فاتورة متبقي على صلاحيتها  ",
    "jours":" يوم",
    "cachet société":" ختم الشركة",
    "cachet client":" توقيع العميل",
    "cachet commercial":"توقيع المبيعات",


	
};

